import * as Actions from './actionTypes/globalActionTypes'
import * as api from '../api'
import makeAction from "./actionCreator";


export const getAllSchools = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_SCHOOLS_REQUESTED))

    try {
        const result = await api.getSchools()
        dispatch(makeAction(Actions.GET_ALL_SCHOOLS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_SCHOOLS_FAILED, {},e))
    }
}

export const getAllJurisdictions = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_JURISDICTIONS_REQUESTED))

    try {
        const result = await api.getJurisdictions()
        dispatch(makeAction(Actions.GET_ALL_JURISDICTIONS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_JURISDICTIONS_FAILED, {},e))
    }
}