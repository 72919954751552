import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import React, {useEffect, useState} from 'react'
import {noop, get, isEmpty, filter, head} from "lodash/fp";
import { connect } from 'react-redux'
import PropTypes from "prop-types";
import { ACCEPTED } from '../../../consts/status.js'
import Unauthorized from "../../Unauthorized";
import {getSelectedTeam,getSelectedTeamsPairings } from '../../../reducers/teamSelectors'
import {CircularProgress} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../common/TabPanel";
import { TEAM_ROLE_TYPE } from '../../../utils/constants'
import * as actions from "../../../actions/teamActions";
import UploadDocument from "./uploadDocument";
import {getCurrentMoot} from "../../../reducers/mootSelectors";
import ordinal from "ordinal-number-suffix";
import {format, utcToZonedTime} from "date-fns-tz";
import {makeStyles} from "@material-ui/core/styles";
import TeamName from "../TeamName";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))
const WrittenSubmissions = ({ router,team, getSelectedTeam = noop, getIsDocumentSubmissionOpen = noop,isTeamDocumentSubmissionOpen = false, getSelectedTeamsPairings = noop, selectedTeamsPairings = [], moot, getCurrentMoot = noop }) => {

    const [isLoading,setIsLoading] = useState(true);
    const classes = useStyles()

    const [tabValue, setTabValue] = React.useState(0)
    const handleTabChange = (event, newValue) => setTabValue(newValue)

    const override = router.location.query.override

    useEffect(() => {
        async function getData() {
            await getSelectedTeam()
            await getIsDocumentSubmissionOpen()
            await getSelectedTeamsPairings()
            await getCurrentMoot()
            setIsLoading(false)
        }
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const isAllowed = get('status',team) === ACCEPTED
    //This teams respondent is the pairing where this team is the claimant
    //const selectedTeamsRespondent = head(filter(p => p.claimant.id === team.id,selectedTeamsPairings))
    //This teams claimant is the pairing where this team is the respondent
    const selectedTeamsClaimant = head(filter(p => p.respondent.id === team.id,selectedTeamsPairings))
    return (
        <Container style={{paddingBottom: '10rem', paddingTop: '4rem'}}>
             { isLoading &&
                <CircularProgress />
            }
            {/*{((!isLoading && !isAllowed && !isTeamDocumentSubmissionOpen.claimantNotOpenYet && isTeamDocumentSubmissionOpen.claimantClosed) ||
            (!isLoading && !isAllowed && !isTeamDocumentSubmissionOpen.respondentNotOpenYet && isTeamDocumentSubmissionOpen.respondentClosed)) &&
                <Unauthorized />
            }*/}
            {!isLoading && !isAllowed &&
                <Unauthorized />
            }
                    
            {!isLoading && isAllowed &&
            <Container maxWidth='xl' className={classes.root} style={{padding: '0'}}>
                <Typography variant='h4'>Written Submissions</Typography>

                    <Tabs value={tabValue} onChange={handleTabChange} style={{ borderBottom: '1px solid #e0e0e0'}}>
                        <Tab label='Claimant Memorandum' />
                        <Tab label='Respondent Memorandum' />
                    </Tabs>

                <TabPanel value={tabValue} index={0} style={{ margin: '2rem 0'}}>
                    {/* When claimant submission is not yet open */}
                    {isTeamDocumentSubmissionOpen.claimantNotOpenYet && override !== isTeamDocumentSubmissionOpen.moot?.openOverrideCode &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>Claimant's Memorandum is due by {format(utcToZonedTime(moot.claimantDocumentUploadEndDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy H:mm')} (HKT).</Typography>
                            <Typography style={{ margin: '1rem 0'}}>You will be able to upload your Claimant's Memorandum through your Team Account shortly.</Typography>
                        </>       
                    }                 
                    {/* When claimant submission is open */}
                    {!isTeamDocumentSubmissionOpen.claimantNotOpenYet && !isTeamDocumentSubmissionOpen.claimantClosed && override !== isTeamDocumentSubmissionOpen.moot?.openOverrideCode &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>Pursuant to the Rules, your team must submit a memorandum in support of the claimant's position by {format(utcToZonedTime(moot.claimantDocumentUploadEndDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy H:mm')} (HKT). Please ensure that your submitted Claimant's Memorandum is in accordance with the formatting requirements set out in the Rules. You may upload as many versions of your Claimant's Memorandum to your Team Account. However, the last version submitted by the submission deadline will be deemed the final version submitted by the team.</Typography>
                            <Typography style={{ margin: '1rem 0'}}>As a kind reminder, failure to submit a Claimant's memorandum within the deadline will result in <strong>automatic disqualification</strong> of your team from the {ordinal(moot.mootNumber)} Vis East Moot and immediate forfeiture of your non-refundable deposit.</Typography>
                        </>
                    }                    
                    {/* When claimant submission is closed */}
                    {!isTeamDocumentSubmissionOpen.claimantNotOpenYet && isTeamDocumentSubmissionOpen.claimantClosed && override !== isTeamDocumentSubmissionOpen.moot?.openOverrideCode &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>The deadline to submit your Claimant's Memorandum has expired. Pursuant to the Rules, the last version of your submission uploaded to your Team Account shall be deemed the final version submitted by your team.</Typography>
                            <Typography style={{ margin: '1rem 0'}}>Pursuant to the Rules, you will be able to access the claimant memorandum to which you will submit your Respondent's Memorandum approximately one week after the deadline for submission of the Claimant's Memorandum. Should the memorandum become available sooner, the Vis East Administration will notify you via email.</Typography>
                        </>
                    }
                    <UploadDocument isOpen={((!isTeamDocumentSubmissionOpen.claimantNotOpenYet && !isTeamDocumentSubmissionOpen.claimantClosed) || override === isTeamDocumentSubmissionOpen.moot?.openOverrideCode)}  isAvailableCritique={isTeamDocumentSubmissionOpen.critiqueIsAvailable} type={TEAM_ROLE_TYPE.CLAIMANT} />
                </TabPanel>
                <TabPanel value={tabValue} index={1} style={{ margin: '2rem 0'}}>
                    {/* When respondent submission is not yet open */}
                    {(isEmpty(selectedTeamsPairings) || isTeamDocumentSubmissionOpen.respondentNotOpenYet) &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>Respondent's Memorandum is due by {format(utcToZonedTime(moot.respondentDocumentUploadEndDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy H:mm')} (HKT).</Typography>
                            <Typography style={{ margin: '1rem 0'}}>You will be able to upload your Respondent's Memorandum through your Team Account sometime after the Claimant’s Memorandum has been submitted and you have received the claimant’s memorandum to which you will submit your Respondent’s Memorandum.</Typography>
                        </>
                    }
                    {/* After it is available for upload */}
                    {!isTeamDocumentSubmissionOpen.respondentNotOpenYet && !isTeamDocumentSubmissionOpen.respondentClosed && !isEmpty(selectedTeamsPairings) &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>Pursuant to the Rules, your team must submit a memorandum in support of the respondent's position <u>in response to the claimant's memorandum</u> that you received. The deadline for such submission is {format(utcToZonedTime(moot.respondentDocumentUploadEndDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy H:mm')} (HKT). Please ensure that your submitted Respondent's Memorandum is in accordance with the formatting requirements set out under the Rules. You may upload as many versions of your Respondent's Memorandum to your Team Account. However, please note that the last version submitted by the submission deadline will be deemed the final version submitted by the team.</Typography>
                            <Typography style={{ margin: '1rem 0'}}>As a kind reminder, as set out in the Rules, it is <u>absolutely essential</u> that your Respondent's Memorandum is responsive to all the arguments made in the memorandum for claimant. Respondent's memorandum should also address issues your team believes should be raised, even if the argument was not raised by the claimant's memorandum.</Typography>                     
                        </>
                    }
                    
                    {/* When respondent submission is closed */}
                    {!isTeamDocumentSubmissionOpen.respondentNotOpenYet && isTeamDocumentSubmissionOpen.respondentClosed &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>The deadline to submit your Respondent's Memorandum has elapsed. Pursuant to the Rules, the last version of your submission uploaded to your Team Account shall be deemed the final version submitted by your team.</Typography>
                            <Typography style={{ margin: '1rem 0'}}>Also pursuant to the Rules, you are now allowed to exchange your written memoranda with other teams in preparation for the oral hearings.</Typography>                            
                        </>
                    }
                    {!isEmpty(selectedTeamsPairings) && !isTeamDocumentSubmissionOpen.respondentNotOpenYet &&
                        <>
                            <Typography>{!isTeamDocumentSubmissionOpen.respondentNotOpenYet && isTeamDocumentSubmissionOpen.respondentClosed ? 'You responded to ' : 'Please respond to '} <strong><TeamName team={get('claimant',selectedTeamsClaimant)} /></strong> claimant memorandum <a href={get('claimantDocUrl',selectedTeamsClaimant)}>{get('claimantDocName',selectedTeamsClaimant)}</a> </Typography>
                            <UploadDocument isOpen={((!isTeamDocumentSubmissionOpen.respondentNotOpenYet && !isTeamDocumentSubmissionOpen.respondentClosed) || override === isTeamDocumentSubmissionOpen.moot?.openOverrideCode)} isAvailableCritique={isTeamDocumentSubmissionOpen.critiqueIsAvailable} type={TEAM_ROLE_TYPE.RESPONDENT} />
                        </>
                    }
                </TabPanel>
            </Container>
            }
        </Container>
    )
}


WrittenSubmissions.propTypes = {
    getSelectedTeam: PropTypes.func,
    team: PropTypes.object,
    claimant: PropTypes.object,
    getIsDocumentSubmissionOpen: PropTypes.func,
    isTeamDocumentSubmissionOpen: PropTypes.object,
    getSelectedTeamsPairings: PropTypes.func

}

export default connect(
    (state, ownProps) => ({
        router: state.router,
        team: state.team.selectedTeam,
        isTeamDocumentSubmissionOpen: state.team.isTeamDocumentSubmissionOpen,
        selectedTeamsPairings : state.team.selectedTeamsPairings,
        moot: state.moot.currentMoot
    }),
    {
        getSelectedTeam: getSelectedTeam,
        getSelectedTeamsPairings: getSelectedTeamsPairings,
        getIsDocumentSubmissionOpen: actions.getIsDocumentSubmissionOpen,
        getCurrentMoot: getCurrentMoot
    }
)(WrittenSubmissions)