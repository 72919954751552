export const GET_ALL_SCHOOLS_REQUESTED = 'GET_ALL_SCHOOLS_REQUESTED'
export const GET_ALL_SCHOOLS_SUCCEEDED = 'GET_ALL_SCHOOLS_SUCCEEDED'
export const GET_ALL_SCHOOLS_FAILED = 'GET_ALL_SCHOOLS_FAILED'



export const GET_ALL_JURISDICTIONS_REQUESTED = 'GET_ALL_JURISDICTIONS_REQUESTED'
export const GET_ALL_JURISDICTIONS_SUCCEEDED = 'GET_ALL_JURISDICTIONS_SUCCEEDED'
export const GET_ALL_JURISDICTIONS_FAILED = 'GET_ALL_JURISDICTIONS_FAILED'


