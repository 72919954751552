import Container from '@material-ui/core/Container'
import React from 'react'
import {Typography} from '@material-ui/core'
import TeamsTable from './TeamsTable'
import ArbitratorTable from './ArbitratorTable'
import ClarificationsTable from './ClarificationsTable'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '../../common/TabPanel'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}))

const AdminDashboard = () => {

  const classes = useStyles()
  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event, newValue) => setTabValue(newValue)


  return (
      <Container maxWidth='xl' className={classes.root}>
        <Typography variant='h4'>Administration Dashboard</Typography>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label='Teams' />
          <Tab label='Arbitrators' />
          <Tab label='Clarifications' />
          {/*<Tab label='Pairing' />*/}
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <TeamsTable />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ArbitratorTable />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ClarificationsTable />
        </TabPanel>
          {/*<TabPanel value={tabValue} index={3}>*/}
          {/*    <Pairing />*/}
          {/*</TabPanel>*/}
      </Container>
  )
}


export default AdminDashboard
