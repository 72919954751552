import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getSelectedTeam, getTeamPaymentHistory} from "../../reducers/teamSelectors";
import React, {useEffect} from "react";
import {Container, CircularProgress} from "@mui/material";
import {get, noop, last} from "lodash/fp";
import {Grid, Tooltip, Typography} from "@material-ui/core";
import PaymentProcessing from "../registration/PaymentProcessing";
import './Payments.css'
import {asHKCurrency} from "../helpers";
import PaymentHistory from "./PaymentHistory";
import {ACCEPTED} from "../../consts/status";
import Unauthorized from "../Unauthorized";

const Payments = ({
                      team,
                      paymentHistory,
                      getTeamPaymentHistory = noop,
                      getSelectedTeam = noop
                  }) => {

    const [isLoading, setIsLoading] = React.useState(false)
    const [isLoadingHistory, setIsLoadingHistory] = React.useState(false)


    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                const team = await getSelectedTeam()
                await getTeamPaymentHistory(team.id)
                setIsLoading(false)
            } catch (e) {
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reloadPaymentHistory = async () => {
        try {
            setIsLoadingHistory(true)
            await getTeamPaymentHistory(team.id, true)
            setIsLoadingHistory(false)
        }catch{
            setIsLoadingHistory(false)
        }
    }

    //you have to have a team record to be allowed see this page.
    const isAllowed = team !== null

    const balanceOwed = get('balance',last(paymentHistory)) ? get('balance',last(paymentHistory)) : 0
    return (
        <Container style={{paddingBottom: '10rem'}}>
            {isLoading &&
                <CircularProgress/>
            }
            {!isLoading && !isAllowed &&
                <Unauthorized />
            }
            {!isLoading && isAllowed &&
                <>
                <Typography variant='h4' component='h1' style={{marginBottom: '1rem'}}>Payment & Finances</Typography>
                    <Grid item xs={12}
                          style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>
                        <Typography variant='h5' component='h1'>Payment</Typography>
                        <Grid item xs={12} style={{marginTop: "1rem"}}>
                            <PaymentProcessing showExsitingPaymentNotification={false}
                                               paymentLabel={ balanceOwed < 0
                                                   ? `You owe ${asHKCurrency(Math.abs(balanceOwed))}`
                                                   : balanceOwed === 0 ? `You owe ${asHKCurrency(0)}`
                                                   : `You have a credit of ${asHKCurrency(balanceOwed)}`
                                                
                                                }
                                               paymentHelperText={''}
                                               onPaymentCompleted={reloadPaymentHistory}
                                               initialAmount={balanceOwed < 0 ? Math.abs(balanceOwed) : 0}
                                               minAmount={0}
                                               maxAmount={99999}
                                               showBankTransferRegInstructions={false}
                                               transactionType="ADDITIONAL" />
                        </Grid>
                    </Grid>
                    <PaymentHistory busy={isLoadingHistory} paymentHistory={paymentHistory} team={team} />
                </>
            }
        </Container>
    )
}


Payments.propTypes = {
    getSelectedTeam: PropTypes.func,
    getTeamPaymentHistory: PropTypes.func,
    team: PropTypes.object,
    paymentHistory: PropTypes.array
}

export default connect(
    (state) => ({
        team: state.team.selectedTeam,
        paymentHistory: state.team.paymentHistory
    }), {
        getSelectedTeam: getSelectedTeam,
        getTeamPaymentHistory: getTeamPaymentHistory
    })(Payments)