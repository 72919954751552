import * as actionTypes from "../actions/actionTypes/globalActionTypes"
import {combineReducers} from "redux";
import {map} from "lodash/fp";


const schools = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_SCHOOLS_SUCCEEDED) {
        return payload
    }else {
        return state
    }
}
const schoolNames = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_SCHOOLS_SUCCEEDED) {
        return map(s => s.name,payload)
    }else {
        return state
    }
}

const jurisdictions = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_JURISDICTIONS_SUCCEEDED) {
        return payload
    }else {
        return state
    }
}
const jurisdictionNames = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_JURISDICTIONS_SUCCEEDED) {
        return map(s => s.name,payload)
    }else {
        return state
    }
}

export default combineReducers({
    schools,
    schoolNames,
    jurisdictions,
    jurisdictionNames
})