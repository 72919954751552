import PaymentHistory from "../../team/PaymentHistory";
import {connect} from "react-redux";
import { noop, filter, head, get } from 'lodash/fp'
import {getAllTeams} from "../../../reducers/adminSelectors";
import React, {useEffect, useState} from "react";
import TextInput from "../../common/TextInput";
import {asHKCurrency, formatCurrency} from "../../helpers";
import {Box, CircularProgress, Container, Button, FormLabel, Grid, Typography} from "@material-ui/core";
import {saveTeamOrder} from 'actions/teamActions'
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const AdminTeamPayments = ({selectedTeamId = 0,teams = [],getTeams = noop, saveTeamOrder = noop}) =>{

    const [busy, setBusy] = useState(false)
    const [description, setDescription] = useState('')
    const [amount,setAmount] = useState('')
    const [selectedTeam,setSelectedTeam] = useState(head(filter(t => t.id === parseInt(selectedTeamId),teams)))

    const onAddPayment = async() => {
        try{
            setBusy(true)
            const type = parseFloat(amount) < 0 ? 'FEE' : 'CREDIT'
            await saveTeamOrder(selectedTeamId, {}, description, amount, type )            //Save payment
            const teams = await getTeams(true)
            setSelectedTeam(head(filter(t => t.id === parseInt(selectedTeamId),teams)))
            setDescription('')
            setAmount('')

        }catch
        {}
        setBusy(false)
    }

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setBusy(true)
                const teams = await getTeams()
                setSelectedTeam(head(filter(t => t.id === parseInt(selectedTeamId),teams)))
                setBusy(false)
            }catch(e){
                //TODO ?
                setBusy(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <Container style={{paddingBottom: '10rem'}}>
            { !selectedTeam &&
                <CircularProgress />
            }
            {selectedTeam &&
                <>
                    <Button style={{ margin: '0 0 2rem 0' }} variant='text' component={Link}  to='/admin' >
                            <ArrowBackIosIcon /> Back to Administration Dashboard
                    </Button>
                    <Typography variant='h4' component='h1'>{selectedTeam.school}</Typography>
                    <PaymentHistory busy={busy} showAdminFeatures={true}
                                    paymentHistory={get('PaymentHistory', selectedTeam)}
                                    team={selectedTeam}/>
                    <Box style={{
                        background: "#F0F0F0",
                        marginTop: "4rem",
                        padding: "1.75rem",
                        border: "1px solid #D8D8D8"
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography>To apply a credit to the account enter a positive dollar amount. To apply a
                                    fee to the account enter a negative dollar amount (e.g. -1000)</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput onChange={e => {
                                    setDescription(e.target.value)
                                }}
                                           value={description}
                                           label='Description'
                                           type="text"
                                           required>

                                </TextInput>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput onChange={e => {
                                    setAmount(e.target.value)
                                }}
                                           value={amount}
                                           label='Amount'
                                           type="number"
                                           helperText={
                                               amount && formatCurrency(amount, `This will apply a ${parseInt(amount) < 0 ? 'fee' : 'credit'} of`)
                                           }
                                           required>
                                </TextInput>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button variant='contained' color="primary" disabled={busy}
                                        onClick={onAddPayment}>Add</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }
        </Container>
    )
}


export default connect(
    (state, ownProps) => ({
        selectedTeamId: ownProps.match.params.teamId,
        teams: state.admin.teams
    }),{
        getTeams: getAllTeams,
        saveTeamOrder: saveTeamOrder
    })( AdminTeamPayments)