import * as actions from '../actions/globalActions'
import { isEmpty, get } from "lodash/fp";

export const getAllSchools = () => async (dispatch,getState) => {
    const schools = get('global.schools', getState())
    if (isEmpty(schools)) {
        await (actions.getAllSchools())(dispatch, getState)
    }
}

export const getAllJurisdictions = () => async (dispatch,getState) => {
    const jurisdictions = get('global.jurisdictions', getState())
    if (isEmpty(jurisdictions)) {
        await (actions.getAllJurisdictions())(dispatch, getState)
    }
}