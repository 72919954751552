import React, {useState} from 'react'
import { Button, CircularProgress, Tooltip} from '@material-ui/core'
import {DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {get, isEmpty, noop} from 'lodash/fp';
import { Delete, Edit } from '@mui/icons-material';
import {connect} from "react-redux";
import {getSelectedTeam} from "../../reducers/teamSelectors";
import {saveTeamMember, deleteTeamMember, getIsTeamMemberOpen} from "../../actions/teamActions";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Select from "../common/Select";
import Grid from "@material-ui/core/Grid";
import TextField from "../common/TextInput";
import TextInput from "../common/TextInput";
import {ACCEPTED} from "../../consts/status";
import {ROLES, GENDERS} from "../../utils/constants";
import {isEmailValid} from "../../utils/validation";
import Unauthorized from "../Unauthorized";
import {Autocomplete, TextField as MUITextField} from "@mui/material";
import {getAllJurisdictions} from "../../reducers/globalSelectors";
import ordinal from "ordinal-number-suffix";
import {asHKCurrency, formatCurrency} from "../helpers";
import {getCurrentMoot} from "../../reducers/mootSelectors";
LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);

const TeamsMembers = ({   router,
                          team = {},
                          getSelectedTeam = noop,
                          saveTeamMember = noop,
                          deleteTeamMember = noop,
                          getIsTeamMemberOpen = noop,
                          isTeamMemberOpen = {},
                          teamMemberSaveError,
                          moot, 
                          getCurrentMoot = noop }) => {

    const [busy, setBusy] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const defaultNewTeamMember = {
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        involvement: '',
        jurisdiction: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        gender: '',
        phone: '',
        willBeInPerson: '',
        isStudent: '',
    }
    const [newTeamMember,setNewTeamMember] = useState(defaultNewTeamMember )
    const [existingInPerson,setExistingInPerson] = useState()
    
    const [isSaving,setIsSaving] = useState(false);
    const override = router.location.query.override


    const isNonStudentCompetitor = newTeamMember.involvement === 'Competitor' && newTeamMember.isStudent === false
    const isClosed = isTeamMemberOpen.closed
    const isGoingToHK = newTeamMember.willBeInPerson === true 
    const isExistingInPersonFalse = existingInPerson === false

    const doEmailsMatch = newTeamMember.email ? newTeamMember.email === newTeamMember.confirmEmail : true
    const isIncorrectEmail =
        !isEmpty(newTeamMember.email) &&
        !isEmailValid(newTeamMember.email)

    const isNameSameAsAnother = () => {        

        if(team?.members) {

            let i
            for(i=0; i < team.members.length; i++){      

                if(newTeamMember.firstName.trim().toLowerCase() === team.members[i].firstName.trim().toLowerCase() && 
                    newTeamMember.lastName.trim().toLowerCase() === team.members[i].lastName.trim().toLowerCase() &&
                    newTeamMember.id !== team.members[i].id) {
                    return true
                }

            }

        }
        return false
    }  
    /**
     * canSaveWhenOpen is true when emails match and are valid and not empty, the person's name is unique
     * within the team and the person is not a non-student competitor.
     */
    const canSaveWhenOpen = (doEmailsMatch && !isIncorrectEmail && !isNameSameAsAnother(true) && !isNonStudentCompetitor)

    /**
     * canSave is true when canSaveWhenOpen is trie and when the following conditions are met:
     * Team Members is closed and the person is going to Hong Kong or
     * Team Members is closed and the person was not going to Hong Kong prior to team members closed date or
     * Team Members is not closed.
     */
    const canSave = canSaveWhenOpen && (( (isClosed && isGoingToHK) || (isClosed && isExistingInPersonFalse) ) || !isClosed)


    const ImportantNotice = () => {        
      
        return (
            <>
                <Typography><strong>IMPORTANT NOTICE:</strong> It is the responsibility of each Team Member to comply with immigration formalities for both Hong Kong and the Team Member's own jurisdiction. Your passport must be valid for at least six months after your planned entry into Hong Kong. Depending on your nationality, a visitor visa may be necessary. We strongly encourage all visitors to Hong Kong to obtain adequate travel and medical insurance, irrespective of whether it is mandated by law.  
                </Typography>
                <Typography>
                Team Members must ensure that every one of them has the corresponding proof available to show the airline before traveling to Hong Kong, or boarding may be refused. According to the Hong Kong Immigration Department, it normally takes at least four to six weeks to process entry visas to Hong Kong. The Immigration Department will not start processing the application until all required documents and information have been received. Depending on how busy the Hong Kong Immigration Department is, it can take up to several months to process visa applications. You should start compiling these documents immediately, and submit your visa application at the earliest possible date.
                </Typography>
                <Typography>Please also note, while entry restrictions have been relaxed by the HKSAR government, Team Members traveling to Hong Kong (just as any other country in the world) should regularly monitor the Hong Kong government website: <a target="_blank" rel="noreferrer" href="https://www.coronavirus.gov.hk/" className="color-blue">COVID-19 Thematic Website - Together, We Fight the Virus - Inbound Travel</a> to ensure they are up to date with all requirements to enter into Hong Kong.
                </Typography>
            </>);
      };

    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                await getSelectedTeam()
                await getIsTeamMemberOpen()
                await getCurrentMoot()
                setIsLoading(false)
            }catch(e){
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1,
            hide: true,
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 1,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            renderCell: params => (<a href={`mailto:${params.row.email}`}>{params.row.email}</a>),
            valueGetter: params => (params.row.email)
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 1,
            hide: true,
        },
        {
            field: 'involvement',
            headerName: 'Role',
            flex: 1
        },
        {
            field: 'student',
            headerName: 'Student',
            flex: 1,
            valueGetter: params => params.row.isStudent === true ? 'YES' : 'NO'
        },
        {
            field: 'willBeInPerson',
            headerName: 'Going to HK',
            flex: 1,
            valueGetter: params => params.row.willBeInPerson === true ? 'YES' : (params.row.willBeInPerson === false ? 'NO': '')
        },
        {
            field: 'city',
            headerName: 'City',
            flex: 1,
            hide: true,
        },
        {
            field: 'edit',
            type: "actions",
            headerName: 'Edit',
            getActions: params => ([<GridActionsCellItem
                icon={
                    <Tooltip title='Edit'>
                        <Edit/>
                    </Tooltip>
                }
                onClick={async () => {
                    const existingMember = params.row
                    existingMember.confirmEmail = params.row.email
                    setExistingInPerson(existingMember.willBeInPerson)
                    setNewTeamMember(existingMember)
                    const element = document.getElementById("addTeamMember")
                    element.scrollIntoView({behavior: "smooth"})
                    }}
                label="Edit"
                />])
        },
        {
            field: 'delete',
            type: "actions",
            headerName: 'Delete',
            getActions: params => ([<GridActionsCellItem
                icon={
                    <Tooltip title='Delete'>
                        <Delete/>
                    </Tooltip>
                }
                onClick={async () => {
                    const result = window.confirm(`Are you sure you want to delete ${params.row.firstName} ${params.row.lastName}? `);
                    if (result) {
                        try {
                            setBusy(true)
                            await deleteTeamMember(params.row.id)
                        }finally {
                            setBusy(false)
                        }
                    }
                }}
                label="Delete"
                disabled={isTeamMemberOpen.closed}/>])
        }
    ]
    const isAllowed = get('status',team) === ACCEPTED
    const isPending = get('status',team) === null

    const onSave = async e =>  {
        try{
            e.preventDefault()
            setIsSaving(true)

            const result = await saveTeamMember(newTeamMember,override)
            if (result){
                setNewTeamMember(defaultNewTeamMember)
                setExistingInPerson('')

                setTimeout(() => {
                    const element = document.getElementById("teamMembersTable")
                    element.scrollIntoView({behavior: "smooth"})
                  }, 250)
                
            }
            
        }finally{
            setIsSaving(false)
        }
    }

    return (

        <Container style={{paddingBottom: '10rem'}}>
            { isLoading &&
                <CircularProgress />
            }
            {!isLoading && !isAllowed && !isPending &&
                <Unauthorized />
            }            
            {!isLoading && isTeamMemberOpen.notOpenYet && !isTeamMemberOpen.closed && override !== isTeamMemberOpen.moot?.openOverrideCode &&
                <div>
                    <Typography variant='h4' component='h1'>Team Member additions will open soon</Typography>
                </div>
            }
            { !isLoading && isPending && !isTeamMemberOpen.notOpenYet && !isTeamMemberOpen.closed && override !== isTeamMemberOpen.moot?.openOverrideCode &&
                <div style={{ marginLeft: '4rem' }}>
                    <Typography variant='h5' component='h1'>This section it not yet available</Typography>
                </div>
            }
            {!isLoading && isAllowed && !isTeamMemberOpen.notOpenYet &&
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>                        
                            <Typography variant='h4' component='h1' style={{ margin: '1rem 0'}}>Team Members</Typography>                        
                            <Typography>
                            For this page, Teams are required to <strong>list</strong> out each member of their Team who will be taking part in the Vis East Moot Competition. Please ensure all the information on this page is completed accurately and in full.
                            </Typography>
                            <Typography>
                            Please note, ALL team members who wish to receive certificates of participation / letters of recognition must be listed out in the Team List. Further, team members who will be physically present in Hong Kong must be registered on this Team Members page, with the box next to their names indicating that they will be coming to Hong Kong, in order to attend the Vis East Moot associated events during the week of Oral Arguments.
                            </Typography>
                            <Typography>
                            The <strong>Standard Registration Fee</strong> of {asHKCurrency(Math.abs(moot.inPersonRegistrationFee),0)} applies to teams comprised of four (4) to eight (8) Team Members. Teams comprised of two (2) or three (3) Team Members pay a reduced registration fee of {asHKCurrency((Math.abs(moot.inPersonRegistrationFee) - 500), 0)} (“<strong>Small Team Registration Fee</strong>”). Teams comprised of more than eight (8) team members pay the Standard Registration Fee (<i>i.e.</i> {asHKCurrency(Math.abs(moot.inPersonRegistrationFee),0)}) <u>plus</u> an additional fee of HK$ 1,500 for each individual Team Member over eight (8). For the avoidance of doubt, “Team Members” are those individuals who are a part of the team, whether as a student participant, student coach, or coach, who wish to receive certificates of participation / letters of recognition, irrespective of whether or not they will physically be present in Hong Kong.
                            </Typography>
                            <Typography>
                            By adding team members and their contact details to your Team Account, you confirm that you have authority from {team.school} to do so. You also confirm that the team members you are adding to the Team Account have consented to the use and communication of their personal information for the organizational and operational purposes of the Vis East Moot Competition and agree to receive communications in accordance with Hong Kong's privacy laws and the Rules of the Vis East Moot Competition.
                            </Typography>
                            <Typography>
                            Please ensure that all information inserted on the Team Members page is accurate and up to date. In accordance with the Vis East Moot Rules, the Vis East Moot Administration will issue certificates (participation and awards) / letters of recognition for Team Members using verbatim the information registered on this Team Account. Only students who are part of the {team.school}’s {ordinal(moot.mootNumber)} Vis East Moot Team, as set out in this Team Members page and in accordance with the {ordinal(moot.mootNumber)} Vis East Moot Competition Rules, are eligible to receive certificates of participation / letters of recognition. The Vis East Moot Administration will also use the addresses registered on the Team Account for all communications from the Vis East Moot. It is the team's responsibility to ensure that all Team Members' information in the Team Account is, and remains, accurate, and is updated regularly or as necessary until the end of the Vis East Moot.
                            </Typography>                            
                        </Grid>
                    </Grid>
                    <div id="teamMembersTable" style={{marginTop: '2rem', width: '100%'}}>
                        <DataGridPro
                        loading={busy}
                        rows={get('members',team) || []}
                        columns={columns}
                        disableSelectionOnClick
                        autoHeight={true}
                        initialState={{
                            sorting: {
                              sortModel: [{ field: 'createdAt', sort: 'asc' }],
                            },
                          }}
                        />
                    </div>                   
                    
                    <form onSubmit={onSave}>
                        
                        <Grid id="addTeamMember" container style={{marginTop: '2rem'}} spacing={2}>                        
                        {
                        /***
                         * Allow edit of existing Team Member after close date or if not closed.
                         * Don't show for new Team Member after close date. 
                         */
                        ((isClosed && newTeamMember.willBeInPerson !== '') || (!isClosed) || (override === isTeamMemberOpen.moot?.openOverrideCode)) ?
                        <>
                            <Grid item xs={12}>
                                <Typography variant='h5' component='h2'>{existingInPerson === true || existingInPerson === false ? 'Edit' : 'Add'} Team Member</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    onChange={(event, newValue) => setNewTeamMember({
                                        ...newTeamMember,
                                        involvement: newValue
                                    })}
                                    value={newTeamMember.involvement || ''}
                                    options={ROLES}
                                    autoSelect
                                    renderInput={(params) => <MUITextField {...params} variant="standard" label="Role" required />}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Select name='student'
                                        required
                                        onChange={ e => setNewTeamMember({...newTeamMember, isStudent: e.target.value === 'YES'})}
                                        value={newTeamMember.isStudent === true ? 'YES' :  (newTeamMember.isStudent === false ? 'NO' : '') }
                                        label='Student?'
                                        options={['YES', 'NO']}
                                        helperText={ isNonStudentCompetitor ? <Typography variant='caption' color='error'>Non-students can only be coaches, they cannot be competitors.</Typography> : ''}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>                                
                                <Select name='hongKong'
                                        required
                                        onChange={e => setNewTeamMember({...newTeamMember, willBeInPerson: e.target.value === 'YES'})}
                                        value={newTeamMember.willBeInPerson === true ? 'YES' :  (newTeamMember.willBeInPerson === false ? 'NO' : '') }
                                        label='Going to Hong Kong?'
                                        options={['YES', 'NO']}
                                        helperText={!(( (isClosed && isGoingToHK) || (isClosed && isExistingInPersonFalse) ) || !isClosed) && !(newTeamMember.willBeInPerson !== true && newTeamMember.willBeInPerson !== false) ? <Typography variant='caption' color='error'>Please contact the Vis East Administration</Typography> : ''}
                                       
                                />
                                 
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput label='First Name'
                                           value={newTeamMember.firstName}
                                           onChange={e => setNewTeamMember({...newTeamMember, firstName: e.target.value})}/>

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput label='Last Name'
                                           value={newTeamMember.lastName}
                                           onChange={e => setNewTeamMember({...newTeamMember, lastName: e.target.value})}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput label='Address Line 1'
                                           notRequired
                                           value={newTeamMember.addressLine1}
                                           onChange={e => setNewTeamMember({...newTeamMember, addressLine1: e.target.value})}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput label='Address Line 2'
                                           notRequired
                                           value={newTeamMember.addressLine2}
                                           onChange={e => setNewTeamMember({...newTeamMember, addressLine2: e.target.value})}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput label='City'
                                           notRequired
                                           value={newTeamMember.city}
                                           onChange={e => setNewTeamMember({...newTeamMember, city: e.target.value})}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput label='State / Province'
                                           notRequired
                                           value={newTeamMember.state}
                                           onChange={e => setNewTeamMember({...newTeamMember, state: e.target.value})}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput label='Zip / Postal Code'
                                           notRequired
                                           value={newTeamMember.zip}
                                           onChange={e => setNewTeamMember({...newTeamMember, zip: e.target.value})}/>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <TextField
                                    name='phoneNumber'
                                    onChange={e => setNewTeamMember({...newTeamMember, phone: e.target.value})}
                                    value={newTeamMember.phone}
                                    label='Phone Number'
                                    helperText='Include (country code) (regional code) (local number)'/>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Select name='gender' required
                                    helperText='For statistical purposes only'
                                    onChange={e => setNewTeamMember({...newTeamMember, gender: e.target.value})}
                                    value={newTeamMember.gender} label='Gender' options={GENDERS}/>

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name='email'
                                    autocomplete='email'
                                    onChange={e => setNewTeamMember({...newTeamMember, email: e.target.value})}
                                    value={newTeamMember.email}
                                    label='Email'
                                />
                                {isIncorrectEmail && <Typography color='error'>Please enter Valid Email</Typography>}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name='email-confirm'
                                    onChange={e => setNewTeamMember({...newTeamMember, confirmEmail: e.target.value})}
                                    autocomplete='email'
                                    value={newTeamMember.confirmEmail}
                                    label='Confirm Email'
                                />
                                {!doEmailsMatch && <Typography color='error'>Emails must match</Typography>}


                            </Grid>
                            <Grid item xs={12}>
                                <ImportantNotice/>
                            </Grid>
                            <Grid item xs={12} style={{display:'flex', alignItems:'start'}}>
                                <Button variant='contained'
                                        type='submit'
                                        color="primary"
                                        disabled={isSaving || !canSave}>Submit</Button>                                        
                                {isSaving &&
                                <CircularProgress style={{marginLeft: '2rem'}} size={35}/>
                                }
                            </Grid>
                            {isNameSameAsAnother(true) &&
                             <Typography color='error'>Team Members can only be listed once. It appears that you have inputted this individual as a member of the Team already. If you have two Team Members who have the same name, please differentiate the Team Members when listing out the First and/or Last Name.</Typography>
                            }
                            {teamMemberSaveError &&
                            <Typography color='error'>{teamMemberSaveError}</Typography>
                            }
                        </>
                        :
                        <Grid item xs={12}>
                            <ImportantNotice/>
                         </Grid>
                        }
                        </Grid>
                    </form>                    
                                          
                </>
            }
        </Container>
    )
}



TeamsMembers.propTypes = {
    teamMembers: PropTypes.arrayOf(PropTypes.object),
    getSelectedTeam: PropTypes.func,
    isTeamMemberOpen: PropTypes.object,
    saveTeamMember: PropTypes.func,
    teamMemberSaveError: PropTypes.string,
    deleteTeamMember: PropTypes.func,
    getIsTeamMemberOpen: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
        router: state.router,
        team: state.team.selectedTeam,
        isTeamMemberOpen: state.team.isTeamMemberOpen,
        teamMemberSaveError: state.team.teamMemberSaveError,
        moot: state.moot.currentMoot
    }),{
        getSelectedTeam: getSelectedTeam,
        saveTeamMember: saveTeamMember,
        deleteTeamMember: deleteTeamMember,
        getIsTeamMemberOpen: getIsTeamMemberOpen,
        getCurrentMoot: getCurrentMoot
    })( TeamsMembers)