import React from 'react'
import {DataGridPro, GridToolbarContainer, LicenseInfo, GridToolbarExport } from '@mui/x-data-grid-pro';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {format } from "date-fns";
import {Typography} from "@material-ui/core";

import './score.css'
import {Check} from "@material-ui/icons";
import TeamName from "../../../team/TeamName";

LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);


const OralistResults = ({ selectedRoundOralistResults = []}) => {

    const CustomToolbar = () => {
        return (
          <GridToolbarContainer>
            <GridToolbarExport 
            printOptions={{ disableToolbarButton: true }}
            csvOptions={{
                fileName: `Oralist Results - ${format(new Date(),'yyyy-MM-dd')}`,
              }}
            />
            <Typography style={{fontSize:'11px', color:'#808080'}}>(Export DOES NOT indicate scores that are practice rounds)</Typography>
          </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'firstName',
            headerName: 'Oralist',
            flex: 1,
            valueGetter: params => `${params.row.firstName} ${params.row.lastName}`
        },
        {
            field: 'school',
            headerName: 'School',
            flex: 1,
            renderCell: params => <TeamName team={params.row.school} adminUse={true} />,
            valueGetter: params => (params.row.school.hideTeamName === true ? `TEAM ID ${params.row.school.id}` : params.row.school.school)
        },
        {
            field: 'jurisdiction',
            headerName: 'Jurisdiction',
            flex: 1,
        },
        {
            field: 'arg1Score',
            headerName: 'Argument 1',
            flex: 1,
            align: 'right',
            renderCell: params => (params.row.scores[0]?.isPractice ? <span className='practice-score'>{params.row.scores[0]?.score}</span> : <span>{params.row.scores[0]?.score}</span>),
            valueGetter: params => (params.row.scores[0]?.score)
        },
        {
            field: 'arg2Score',
            headerName: 'Argument 2',
            flex: 1,
            align: 'right',
            renderCell: params => (params.row.scores[1]?.isPractice ? <span className='practice-score'>{params.row.scores[1]?.score}</span> : <span>{params.row.scores[1]?.score}</span>),
            valueGetter: params => (params.row.scores[1]?.score)
        },
        {
            field: 'arg3Score',
            headerName: 'Argument 3',
            flex: 1,
            align: 'right',
            renderCell: params => (params.row.scores[2]?.isPractice ? <span className='practice-score'>{params.row.scores[2]?.score}</span> : <span>{params.row.scores[2]?.score}</span>),
            valueGetter: params => (params.row.scores[2]?.score)
        },
        {
            field: 'arg4Score',
            headerName: 'Argument 4',
            flex: 1,
            align: 'right',
            renderCell: params => (params.row.scores[3]?.isPractice ? <span className='practice-score'>{params.row.scores[3]?.score}</span> : <span>{params.row.scores[3]?.score}</span>),
            valueGetter: params => (params.row.scores[3]?.score)
        },
        {
            field: 'arg5Score',
            headerName: 'Argument 5',
            flex: 1,
            align: 'right',
            renderCell: params => (params.row.scores[4]?.isPractice ? <span className='practice-score'>{params.row.scores[4]?.score}</span> : <span>{params.row.scores[4]?.score}</span>),
            valueGetter: params => (params.row.scores[4]?.score)
        },
        {
            field: 'timesClaimant',
            headerName: '#C',
            width: 60,
            align: 'right',
        },
        {
            field: 'timesRespondent',
            headerName: '#R',
            width: 60,
            align: 'right',
        },
        {
            field: 'eligible',
            headerName: 'Eligible?',
            width: 80,
            align: 'center',
            renderCell: params => params.row.timesClaimant >= 1 && params.row.timesRespondent >= 1 ? <Check /> : '',
            valueGetter: params => params.row.timesClaimant >= 1 && params.row.timesRespondent >= 1 ? 'true' : '',
        },
        {
            field: 'total',
            headerName: 'Total Score',
            width: 110,
            align: 'right',
        },
        {
            field: 'avg',
            headerName: 'Average Score',
            width: 110,
            align: 'right',
        },
    ]
    return (
        <div style={{height: 700, width: '100%'}}>
            <DataGridPro
                rows={selectedRoundOralistResults}
                columns={columns}
                disableSelectionOnClick
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'school', sort: 'asc' }],
                    },
                  }}
                components={{
                    Toolbar: CustomToolbar,
                  }}
            />
        </div>
    )
}


OralistResults.propTypes = {
    selectedRoundOralistResults: PropTypes.arrayOf(PropTypes.object)
}

export default connect(
    (state, ownProps) => ({
        selectedRoundOralistResults: state.admin.selectedRoundOralistResults
    }))( OralistResults)
