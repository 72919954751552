import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import { saveTeamRegistrationData } from 'actions/registrationActions'
import {getSelectedTeam, getTeamPaymentHistory} from 'reducers/teamSelectors'
import timezones from 'timezones-list'

import {Grid, Container, Typography, Button, CircularProgress,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel } from '@material-ui/core'
import {isEmpty, noop, map, get} from "lodash/fp";
import {defaultTeam} from "./registrationUtils";
import PaymentProcessing from "./PaymentProcessing";
import TextField from "../common/TextInput";
import {useHistory} from "react-router-dom";
import Select from "../common/Select";
import {DEGREES, GENDERS, LEGAL_SYSTEMS, NATIONALITIES} from "../../utils/constants";
import {getAllSchools, getAllJurisdictions} from "../../reducers/globalSelectors";
import {Autocomplete, TextField as MUITextField} from "@mui/material";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import {getUser} from "../../actions/userActions";
import {getIsTeamRegistrationOpen} from "../../actions/teamActions";
import {PrintOutlined} from "@material-ui/icons";
import downloadInvoice from "../team/downloadInvoice";
import ordinal from 'ordinal-number-suffix'
import {format, utcToZonedTime} from "date-fns-tz";
import {asHKCurrency} from "../helpers";

const TeamRegistration = ({
                              existingTeamData,
                              getSelectedTeam = noop,
                              schools =[],
                              jurisdictions = [],
                              selectedTeam = {},
                              saveTeamRegistrationData = noop,
                              getAllSchools = noop,
                              getAllJurisdictions = noop,
                              getUser = noop,
                              getIsTeamRegistrationOpen = noop,
                              isTeamRegistrationOpen = {},
                              paymentOverride = false,
                              getTeamPaymentHistory = noop,
                              paymentHistory = [],
                          router}) => {
    const history = useHistory()
    const overrideCode = router.location.query.override

    const [error,setError] = useState('')
    const [isLoading,setIsLoading] = useState(false)
    const [pending,setPending] = useState(false)
    const [currentStep,setCurrentStep] = useState(1)
    const [isUsingBankTransfer,setIsUsingBankTransfer] = useState(false)

    const [paymentInProcess,setPaymentInProcess] = useState(false)

    const defaultTeamData = !isEmpty(existingTeamData) ? existingTeamData: defaultTeam

    const [teamData, setTeamData] = useState(defaultTeamData)

    const timeZoneNames = map(x => x.name,timezones)

    useEffect(() => {
        const asyncFetchData = async () => {
            setIsLoading(true)
            try{
                await getIsTeamRegistrationOpen()
                const result = await getSelectedTeam()
                if (result?.id) {
                    await getTeamPaymentHistory(result?.id)
                }
                await getAllSchools()
                await getAllJurisdictions()
                if (!isEmpty(result)) {
                    setTeamData(result)
                }
                if (router.location.query.step) {
                    const resumeStep = parseInt(router.location.query.step)
                    setCurrentStep(resumeStep)
                }
            }catch(e){
            }
            setIsLoading(false)
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const goBack = () => {
        const newStepNumber = currentStep -1
        history.push(`${router.location.pathname}?step=${newStepNumber}${overrideCode ? `&override=${overrideCode}`: ''}`)
        setCurrentStep(newStepNumber)
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        });
    }
    const goForward = () => {
        const newStepNumber = currentStep +1
        history.push(`${router.location.pathname}?step=${newStepNumber}${overrideCode ? `&override=${overrideCode}`: ''}`)
        setCurrentStep(newStepNumber)

        window.scrollTo({
            top:0,
            behavior: 'smooth'
        });
    }

     const save = async e => {
         e.preventDefault()
         try {
             setPending(true)
             await saveTeamRegistrationData(teamData,overrideCode)
             const team = await getSelectedTeam(true)
             await getTeamPaymentHistory(team.id)
             await getUser()
             setPending(false)
             if (currentStep < 3) {
                 goForward()
             }else if (currentStep === 3){
                 history.replace('/team-owner')
             }

         }catch(e){
             setError('Error saving registration. Please try again')
             setPending(false)
         }
     }


     const onPaymentTypeChanged = type => {
         if (type === 'bankTransfer'){
             setIsUsingBankTransfer(true)
         }else{
            setIsUsingBankTransfer(false)
         }
         setTeamData({
             ...teamData,
             paymentType: type
         })
     }

    return (
        <Container style={{ paddingBottom: '10rem' }}>
            { isLoading &&
                <CircularProgress size={35} />
            }
            { !isLoading && (isTeamRegistrationOpen.notOpenYet === true &&  overrideCode !== isTeamRegistrationOpen.moot?.openOverrideCode) &&
                <Grid container style={{flexGrow: 1, padding: '20px'}} spacing={2}>
                    <Grid item xs={12}>
                        <Typography component='h1' variant='h4'>Registration for Teams will open on {format(utcToZonedTime(isTeamRegistrationOpen.moot.teamRegistrationOpenDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy p')}(HKT)</Typography>
                    </Grid>
                </Grid>
            }
            { !isLoading && (isTeamRegistrationOpen.closed === true &&  overrideCode !== isTeamRegistrationOpen.moot?.openOverrideCode) &&
                <Grid container style={{flexGrow: 1, padding: '20px'}} spacing={2}>
                    <Grid item xs={12}>
                    <Typography component='h1' variant='h4'>Registration for Teams is now closed</Typography>
                    {/* 
                        <Typography component='h1' variant='h4'>Team Registration for the {ordinal(isTeamRegistrationOpen.moot.mootNumber)} Vis East Moot will commence at {format(utcToZonedTime(isTeamRegistrationOpen.moot.teamRegistrationOpenDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy p')}(HKT)!</Typography>
                    */}
                    </Grid>
                </Grid>
            }
            {!isLoading && ((isTeamRegistrationOpen.notOpenYet === false && isTeamRegistrationOpen.closed === false) || overrideCode === isTeamRegistrationOpen.moot?.openOverrideCode) &&
                <form onSubmit={save}>
                    <Grid container
                          style={{flexGrow: 1, padding: '20px'}}
                          spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Team Registration Step {currentStep} of 3</Typography>
                        </Grid>
                        {/*STEP 1*/}
                        {currentStep === 1 &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant='body1'>Following a successful in-person 20th VIs East Moot, we are excited to welcome back teams from all over for the {ordinal(isTeamRegistrationOpen?.moot?.mootNumber)} Vis East Moot Competition, which once again will take place <strong>IN PERSON</strong> in Hong Kong. This means that all oral arguments will take place physically in Hong Kong. As with past in-person Vis East Moots, there will be a number of in-person events associated with the Vis East Moot, including the Welcome Ceremony, Assembly, and Awards Gala. There will also be various social networking events for both professionals and students.</Typography>
                                    <Typography variant='body1'>Registration fees for the {ordinal(isTeamRegistrationOpen?.moot?.mootNumber)} Vis East Moot Competition will be {asHKCurrency(Math.abs(isTeamRegistrationOpen?.moot?.inPersonRegistrationFee), 0)} for each team (comprised of 4-8 team members). The registration fee covers the Vis East Moot Competition and Vis East Moot sponsored events (unless otherwise advised), including up to 4 student tickets to the Gala Lunch. The registration fees are subject to certain conditions and details, which will be provided to the teams in due course.</Typography>
                                </Grid>

                                <Grid container item xs={12}
                                      style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>
                                    <Typography variant="h6">School Information</Typography>
                                    <Grid item xs={12} style={{marginTop: "1rem"}}>
                                        <FormControl component='fieldset'>
                                            <FormLabel component='legend' required>
                                                Are you authorized to register for the Vis East and act on behalf of
                                                the team?
                                            </FormLabel>
                                            <RadioGroup
                                                name='type'
                                                value={teamData.registrantAuthorized !== null && teamData.registrantAuthorized.toString()}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    registrantAuthorized: e.target.value === 'true'
                                                })}
                                                row>
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio color='primary' required/>}
                                                    label='Yes'
                                                />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio color='primary' required/>}
                                                    label='No'
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        {teamData.registrantAuthorized === false &&
                                        <Typography variant='body1' style={{color:'red'}}>Only authorized persons may register on behalf of a team.</Typography>
                                        }
                                    </Grid>                                    
                                    <Grid container item spacing={3} sx={12}>
                                        <Grid item xs={12} md={6} style={{margin: "1rem 0 2rem"}}>
                                            <Autocomplete
                                                onChange={(event, newValue) => setTeamData({
                                                    ...teamData,
                                                    school: newValue
                                                })}
                                                value={teamData.school || null}
                                                options={schools}
                                                freeSolo
                                                autoSelect
                                                renderInput={(params) => <MUITextField {...params} variant="standard" label="School" required />}
                                            />
                                        
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3} sx={12}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                value={teamData.schoolInNative || ''}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    schoolInNative: e.target.value
                                                })}
                                                notRequired={true}
                                                name='school-native-alphabet'
                                                label='School in native alphabet'
                                                helperText='Example: Πανεπιστήμιο Ελλάδος'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                value={teamData.timeZone || null}
                                                onChange={(event, newValue) => setTeamData({
                                                    ...teamData,
                                                    timeZone: newValue
                                                })}
                                                options={timeZoneNames}
                                                renderInput={(params) => <MUITextField {...params} variant="standard" label={'Timezone'} required /> }
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                        <TextField
                                            value={teamData.street || ''}
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                street: e.target.value
                                            })}
                                            name='street'
                                            label='Address Line 1'
                                            helperText='Example: Banne Bikslaan 101C'
                                        />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                value={teamData.unit || ''}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    unit: e.target.value
                                                })}
                                                name='unit'
                                                label='Address Line 2'
                                                notRequired
                                                helperText='Example: 3rd floor'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                        <TextField
                                            name='city'
                                            value={teamData.city || ''}
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                city: e.target.value
                                            })}
                                            label='City'
                                            required
                                        />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name='state'
                                                value={teamData.state  || ''}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    state: e.target.value
                                                })}
                                                label='State/Province'
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            onChange={(event,newValue) => setTeamData({
                                                ...teamData,
                                                jurisdiction: newValue
                                            })}
                                            value={teamData.jurisdiction}
                                            options={jurisdictions}
                                            freeSolo
                                            autoSelect
                                            renderInput={(params) => <MUITextField {...params} variant="standard" label='Jurisdiction' required /> }
                                            required
                                        />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                type='zip'
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    zip: e.target.value
                                                })}
                                                value={teamData.zip || ''}
                                                label='Postal Code'
                                                required
                                                helperText='If no postal code, enter "00000"'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                        <Select
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                legalSystem: e.target.value
                                            })}
                                            value={teamData.legalSystem}
                                            options={LEGAL_SYSTEMS}
                                            label={'Legal System'}
                                            required
                                        />
                                        </Grid>
                                        {/*
                                        <Grid item xs={12} md={6}>
                                            <Select
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    attendance: e.target.value
                                                })}
                                                value={teamData.attendance}
                                                options={ATTENDANCES}
                                                label={'Attendance'}
                                                required
                                            />
                                        </Grid>
                                            */}
                                    </Grid>                                 
                                    <Grid item xs={12}>
                                        <FormControl component='fieldset'>
                                            <FormLabel component='legend' required>
                                                How many previous Vis Moots or Vis East Moots has your school participated in?
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    previousMootCount: e.target.value
                                                })}
                                                value={teamData.previousMootCount  || ''}
                                            >
                                                <FormControlLabel
                                                    value='0'
                                                    control={<Radio color='primary' required />}
                                                    label='0'
                                                />
                                                <FormControlLabel
                                                    value='1-5'
                                                    control={<Radio color='primary' required />}
                                                    label='1-5'
                                                />
                                                <FormControlLabel
                                                    value='6-10'
                                                    control={<Radio color='primary' required />}
                                                    label='6-10'
                                                />
                                                <FormControlLabel
                                                    value='11+'
                                                    control={<Radio color='primary' required />}
                                                    label='11+'
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {currentStep === 2 &&
                            <Grid item xs={12}
                                  style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>
                                <Typography variant="h6">Authorized Contact</Typography>
                                <Grid item xs={12} style={{marginTop: "1rem"}}>
                                        <FormControl component='fieldset'>
                                            <FormLabel component='legend' required>
                                                What is your involvement with this team registration?
                                            </FormLabel>
                                            <RadioGroup
                                                name='type'
                                                value={teamData.registrantInvolvement || ''}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    registrantInvolvement: e.target.value
                                                })}
                                                row>
                                                <FormControlLabel
                                                    value='participant'
                                                    control={<Radio color='primary' required/>}
                                                    label='Participant'
                                                />
                                                <FormControlLabel
                                                    value='faculty'
                                                    control={<Radio color='primary' required/>}
                                                    label='Faculty/External Coach'
                                                />
                                                <FormControlLabel
                                                    value='studentCoach'
                                                    control={<Radio color='primary' required/>}
                                                    label='Student Coach'
                                                />
                                                <FormControlLabel
                                                    value='schoolAdministration'
                                                    control={<Radio color='primary' required/>}
                                                    label='School Administration'
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {(teamData.registrantInvolvement === 'participant' ||
                                        teamData.registrantInvolvement === 'studentCoach') &&
                                        <>
                                            <Grid container item spacing={3} xs={12} style={{marginTop: "0"}}>
                                                <Grid item xs={12} md={6}>
                                                        <Select
                                                            name={`degree`}
                                                            onChange={e => setTeamData({
                                                                ...teamData,
                                                                registrantDegree: e.target.value
                                                            })}
                                                            value={teamData.registrantDegree || ''}
                                                            options={DEGREES}
                                                            label={'Degree Pursued'}
                                                            required
                                                        />
                                                </Grid>
                                            </Grid>
                                            <Grid container item spacing={3} xs={12}>                                            
                                                <Grid item xs={12} md={6}>
                                                    <Select
                                                        name={`gender`}
                                                        onChange={e => setTeamData({
                                                            ...teamData,
                                                            registrantGender: e.target.value
                                                        })}
                                                        value={teamData.registrantGender}
                                                        options={GENDERS}
                                                        label={'Gender'}
                                                        required
                                                        helperText='*For statistical purposes only'
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Autocomplete
                                                        onChange={(event, newValue) => setTeamData({
                                                            ...teamData,
                                                            registrantNationality: newValue
                                                        })}
                                                        value={teamData.registrantNationality}
                                                        options={NATIONALITIES}
                                                        freeSolo
                                                        autoSelect
                                                        required
                                                        renderInput={(params) => <MUITextField {...params} variant="standard" label="Nationality" required />}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                <Grid container item spacing={3} xs={12} style={{marginTop: "1rem"}}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantFirstName: e.target.value
                                            })}
                                            value={teamData.registrantFirstName}
                                            label='First Name (or equivalent)'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantLastName: e.target.value
                                            })}
                                            value={teamData.registrantLastName}
                                            label='Last Name'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantPhone: e.target.value
                                            })}
                                            value={teamData.registrantPhone}
                                            name='phone'
                                            label='Phone Number'
                                            helperText='Include (country code) (regional code) (local number)'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} />
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantStreet: e.target.value
                                            })}
                                            value={teamData.registrantStreet}
                                            label='Address Line 1'
                                            helperText='Example: Banne Bikslaan 101C'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantUnit: e.target.value
                                            })}
                                            value={teamData.registrantUnit}
                                            label='Address Line 2'
                                            notRequired
                                            helperText='Example: 3rd floor'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantCity: e.target.value
                                            })}
                                            value={teamData.registrantCity}
                                            label='City'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantState: e.target.value
                                            })}
                                            value={teamData.registrantState}
                                            label='State/Province'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            freeSolo
                                            autoSelect
                                            onChange={(event,newValue) => setTeamData({
                                                ...teamData,
                                                registrantJurisdiction: newValue
                                            })}
                                            value={teamData.registrantJurisdiction}
                                            options={jurisdictions}
                                            renderInput={(params) => <MUITextField {...params} variant="standard" label="Jurisdiction" required /> }
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantZip: e.target.value
                                            })}
                                            value={teamData.registrantZip}
                                            required
                                            label='Postal Code'
                                            helperText='If no postal code, enter "00000"'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup style={{ marginTop: '1rem' }} row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={teamData.hasAuthorityToAddTeamMembers}
                                                        onChange={e => setTeamData({
                                                            ...teamData,
                                                            hasAuthorityToAddTeamMembers: e.target.checked
                                                        })}
                                                        color='primary'
                                                        required
                                                    />
                                                }
                                                label={`I confirm that I have the authority to add team members and the team members’ personal information to the Team's account.`}
                                            />
                                        </FormGroup>
                                        <FormGroup style={{ marginTop: '1rem' }} row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={teamData.acknowledgedVisEastUse}
                                                        onChange={e => setTeamData({
                                                            ...teamData,
                                                            acknowledgedVisEastUse: e.target.checked
                                                        })}
                                                        color='primary'
                                                        required
                                                    />
                                                }
                                                label={`By submitting this form, I acknowledge and consent to the Vis East's use of the submitted information for Vis East and Vis East related purposes.`}
                                            />
                                        </FormGroup>
                                    </Grid>

                                </Grid>
                            </Grid>
                        }
                        {currentStep === 3 &&
                            <>
                                <Button color="primary"
                                        size="small"
                                        startIcon={<PrintOutlined />}
                                        aria-haspopup="menu"
                                        disabled={paymentInProcess}
                                        onClick={() => downloadInvoice(selectedTeam,paymentHistory)}
                                >Print Invoice</Button>
                            <Grid item xs={12}
                                  style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>
                                <Typography variant="h6">Payment</Typography>
                                    <Grid item xs={12} style={{marginTop: "1rem"}}>
                                        {isEmpty(selectedTeam.Payments) &&
                                            <>
                                                <Typography style={{marginBottom: '1rem'}}>Non-refundable Deposit:</Typography>
                                                <Typography variant="h5" display={"inline"}>HK$ 1,500</Typography>
                                                <Typography style={{marginBottom: '1rem', marginTop: "1rem"}}>Teams are responsible for ALL handling fees (bank fees and other payment related charges). Please make sure that you include the charges in the amount that you submit for payment, whether via PayPal, credit card, or bank transfer, so that the Vis East Moot Administration receives the full HK$ 1,500. As a kind reminder, fees will vary depending on the payment method used. Teams are responsible for determining the fees incurred.
                                                </Typography>
                                                <Typography variant='body1'>Please note that Applications will only be considered complete once the Vis East Moot Administration receives full payment of the Team's non-refundable deposit. For PayPal/Credit Card payments, Applications will be deemed completed once the payment is successfully processed. For bank transfers, Applications will be deemed complete once valid proof of bank transfer is received by the Vis East Administration. In this regard, please remember to include your <strong>School’s name</strong> in the Reference/Message so that your payment is easily trackable.</Typography>
                                                <Typography variant='body1'>Before submitting payment of your non-refundable deposit, please ensure that all information contained in your Application is accurate and complete.</Typography>
                                            </>
                                        }
                                        <PaymentProcessing paymentOverride={paymentOverride}
                                                           onPaymentTypeChanged={onPaymentTypeChanged}
                                                           transactionType='INITIAL'
                                                           onPaymentInProcess={() => {
                                                               setPaymentInProcess(true)
                                                           }}
                                                           onPaymentCompleted={async () => {
                                                               await saveTeamRegistrationData(teamData,overrideCode)
                                                               await getTeamPaymentHistory(selectedTeam.id,true)
                                                               setPaymentInProcess(false)
                                                           }}/>
                                    </Grid>
                            </Grid>
                            </>

                        }
                        <Grid item xs={12} style={{marginTop: "1rem", display:"flex", gap:"10px"}}>
                            {currentStep > 1 &&
                                <Button disabled={currentStep === 1} onClick={goBack} variant='contained'
                                        color="secondary">
                                    BACK
                                </Button>
                            }
                            {currentStep < 3 &&
                                <Button  disabled={teamData.registrantAuthorized === false} type="submit" variant='contained' color="primary">
                                    NEXT
                                </Button>
                            }
                            {currentStep === 3 &&
                                <Button disabled={isUsingBankTransfer === false && isEmpty(selectedTeam.Payments)} type="submit" variant='contained' color="primary">
                                    {isUsingBankTransfer ? 'SUBMIT' : 'DONE'}
                                </Button>
                            }
                            {pending && <CircularProgress size={35} />}
                            {error && <Typography color='error'>{error}</Typography>}
                        </Grid>
                    </Grid>
                </form>
            }
        </Container>
            )
}


export default connect(
    (state,ownProps) => ({
        router: state.router,
        existingTeamData: state.registration.teamData,
        schools: state.global.schoolNames,
        jurisdictions: state.global.jurisdictionNames,
        selectedTeam: state.team.selectedTeam,
        isTeamRegistrationOpen: state.team.isTeamRegistrationOpen,
        paymentOverride: ownProps.paymentOverride,
        paymentHistory: state.team.paymentHistory
    }),{
        saveTeamRegistrationData: saveTeamRegistrationData,
        getSelectedTeam: getSelectedTeam,
        getAllSchools: getAllSchools,
        getAllJurisdictions: getAllJurisdictions,
        getUser: getUser,
        getIsTeamRegistrationOpen: getIsTeamRegistrationOpen,
        getTeamPaymentHistory: getTeamPaymentHistory
    }
)(TeamRegistration)
