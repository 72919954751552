import * as actions from '../actions/mootActions'
import { isEmpty, get } from "lodash/fp";

export const getCurrentMoot = (hardRefresh = false) => async (dispatch,getState) => {
    let currentMoot = get('moot.currentMoot',getState())
    if (hardRefresh || isEmpty(currentMoot)){
        await (actions.getCurrentMoot())(dispatch,getState)
        currentMoot = get('moot.currentMoot',getState())
    }
    return currentMoot
}