import React, {useEffect, useState} from 'react'
import {connect} from "react-redux"
import { useHistory } from "react-router"
import { Grid,Container, Typography} from "@material-ui/core"
import {getIsArbitratorRegistrationOpen} from "../../actions/arbitratorActions"
import SignUp from "./SignUp"
import {noop} from "lodash/fp";
import {format, utcToZonedTime} from "date-fns-tz";
import ordinal from "ordinal-number-suffix";

const ArbitratorSignUp = ({ router,getIsArbitratorRegistrationOpen = noop,isArbitratorRegistrationOpen = {}}) => {
    const [isLoading,setIsLoading] = useState(false)


    const history = useHistory()
    const returnTo = router.location.query.returnTo
    const overrideCode = router.location.query.override

    useEffect(() => {
        const asyncFetchData = async () => {
            setIsLoading(true)
            try{
                await getIsArbitratorRegistrationOpen()
            }catch(e){
            }
            setIsLoading(false)
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const handleSignUpComplete = result => {
        if (result === 'UsernameExistsException'){
            history.push(`/existingAccount${returnTo ? `?returnTo=${returnTo}` : ''}`)
        }else{
            history.push(`${returnTo}${overrideCode ? `?override=${overrideCode}` : ''}`)
        }
    }

    const nextMootArbitratorRegistrationYear = (currentArbitratorRegistrationOpenDate) => {
        const nextYear = new Date(currentArbitratorRegistrationOpenDate) 
        return nextYear.setFullYear(nextYear.getFullYear() + 1);
    }

  return (
      <Container style={{ paddingBottom: '10rem' }}>
          { !isLoading && (isArbitratorRegistrationOpen.notOpenYet === true &&  overrideCode !== isArbitratorRegistrationOpen.moot?.openOverrideCode) &&
              <Grid container style={{flexGrow: 1, padding: '20px'}} spacing={2}>
                  <Grid item xs={12}>
                      <Typography component='h1' variant='h4'>Registration for Arbitrators will open mid-October {format(utcToZonedTime(isArbitratorRegistrationOpen.moot.arbitratorRegistrationOpenDate,'Asia/Hong_Kong'), 'yyyy')}. Stay tuned!</Typography>                                         
                  </Grid>
              </Grid>
          }
          { !isLoading && (isArbitratorRegistrationOpen.closed === true &&  overrideCode !== isArbitratorRegistrationOpen.moot?.openOverrideCode) &&
              <Grid container style={{flexGrow: 1, padding: '20px'}} spacing={2}>
                  <Grid item xs={12}>
                      <Typography component='h1' variant='h4'>Registration for Arbitrators for the {ordinal(isArbitratorRegistrationOpen?.moot?.mootNumber + 1)} Vis East will open in October {format(utcToZonedTime(nextMootArbitratorRegistrationYear(isArbitratorRegistrationOpen.moot.arbitratorRegistrationOpenDate),'Asia/Hong_Kong'), 'yyyy')}!</Typography>
                  </Grid>
              </Grid>
          }
          {!isLoading && ((isArbitratorRegistrationOpen.notOpenYet === false && isArbitratorRegistrationOpen.closed === false) || overrideCode === isArbitratorRegistrationOpen.moot?.openOverrideCode) &&


              <Grid container
                    style={{flexGrow: 1, padding: '20px'}}
                    spacing={2}>
                  <Grid item xs={12}>
                      <Typography variant='h4'>Arbitrator Registration</Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <Typography variant='h6'>Create Arbitrator Account</Typography>
                      <Typography variant='body1'>Welcome to the {ordinal(isArbitratorRegistrationOpen?.moot?.mootNumber)} Vis East Moot Competition. Please enter the
                          email you wish to use for your Arbitrator Account and create a password
                          to start the registration process.</Typography>
                  </Grid>
                  <SignUp onSignUpComplete={handleSignUpComplete}/>
              </Grid>
          }
      </Container>)
}


export default connect(
    (state, ownProps) => ({
        router: state.router,
        isArbitratorRegistrationOpen: state.arbitrator.isArbitratorRegistrationOpen
    }), {
        getIsArbitratorRegistrationOpen: getIsArbitratorRegistrationOpen
    })(ArbitratorSignUp)
