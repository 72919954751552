import React, {useState} from "react";
import {connect} from "react-redux";
import {saveTeamOrder} from 'actions/teamActions'

import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";
import {isEmpty, noop, filter,head, get} from "lodash/fp";
import {CircularProgress, Link} from "@material-ui/core";
import {FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";
import TextField from "../common/TextInput";
import {isNotEmpty} from "../../utils/funcUtils";
import {getSelectedTeam} from "../../reducers/teamSelectors";
import {format, utcToZonedTime} from "date-fns-tz";
import {asHKCurrency} from "../helpers";

const PaymentProcessing = ({existingTeamData = {},
                               onPaymentTypeChanged = noop,
                               onPaymentCompleted = noop,
                               selectedTeam ={},
                               saveTeamOrder = noop,
                               getSelectedTeam = noop,
                               paymentOverride = false,
                               showExsitingPaymentNotification = true,
                               paymentLabel='Please input the non-refundable amount.',
                               minAmount=1500,
                               maxAmount=2000,
                               initialAmount=1500,
                               paymentHelperText= `Payment remitted must be no less than ${asHKCurrency(minAmount)}`,
                               transactionType,
                               showBankTransferRegInstructions = true,
                               onPaymentInProcess = noop
                           }) => {

    const labelStyle = {
        fontWeight: 'bold'
    }

    const lineStyle = {
        margin: '.5rem 0'
    }

    const [isBusy,setBusy] = useState(false)
    const [paymentType,setPaymentType] = useState(existingTeamData?.paymentType || 'paypal')
    const [paymentAmount,setPaymentAmount] = useState(initialAmount)
    const [finalPaymentAmount,setFinalPaymentAmount] = useState(initialAmount)
    const [paymentError, setPaymentError] = useState(false)

    const validatePaymentAmount = (data,actions) => {
        if (paymentError) {
            return actions.reject()
        }

        if ((paymentAmount < minAmount || paymentAmount > maxAmount) && paymentOverride === false)
        {
            setPaymentError(`Please enter a payment amount between ${asHKCurrency(minAmount)} and ${asHKCurrency(maxAmount)}`)
            return actions.reject()

        }else{
            setPaymentError(false)
            return actions.resolve()
        }
    }
    const createOrder = async (data, actions) => {
        const orderObject = {
            purchase_units: [
                {
                    amount: {
                        value: finalPaymentAmount,
                    },
                    description: selectedTeam.school
                },
            ],
            application_context:{
                shipping_preference: 'NO_SHIPPING'
            }
        }
        const orderId = await actions.order.create(orderObject);
        return orderId
    };
    const onApprove = async (data, actions) => {
        const order = await actions.order.get(data.orderID)
        let details = order
        if (order.status !== 'APPROVED') {
            await actions.restart();
        }
        else{
            try {
                details = await actions.order.capture()
            }catch(e){
                await actions.order.restart()
            }
            try {
                setBusy(true)
                onPaymentInProcess()

                const amount = get('purchase_units[0].amount.value',details)
                const description = `PayPal: ${get('purchase_units[0].description',details)}`

                await saveTeamOrder(existingTeamData.id, details,description, amount, transactionType)
                await onPaymentCompleted()
                await getSelectedTeam(true)
            }catch{}

            setBusy(false)
        }
        return order

    };
    const initialPayment = head(filter(p => p.type === 'INITIAL',selectedTeam?.Payments))
    return (
        <>
            { isNotEmpty(initialPayment) && showExsitingPaymentNotification &&
                <Typography>A payment in the amount of  {asHKCurrency(initialPayment.amount)} received on {format(utcToZonedTime(initialPayment.createdAt,'Asia/Hong_Kong'), 'eee. d MMMM h:mm aa')} HKT</Typography>
            }
            {(isEmpty(initialPayment)  || !showExsitingPaymentNotification ) &&
                    <>
                    {isBusy ? < CircularProgress size={35}/> : null}
                    {!isBusy &&
                        <>
                            <FormControl component='fieldset'>
                                <FormLabel component='legend' required>
                                    Please select your intended payment method:
                                </FormLabel>
                                <RadioGroup
                                    name='type'
                                    value={paymentType}
                                    onChange={e => {
                                        setPaymentType(e.target.value)
                                        onPaymentTypeChanged(e.target.value)
                                    }}
                                    row>
                                    <FormControlLabel
                                        value="paypal"
                                        control={<Radio color='primary' required/>}
                                        label='Paypal / Credit Card'
                                    />
                                    <FormControlLabel
                                        value="bankTransfer"
                                        control={<Radio color='primary' required/>}
                                        label='Bank Transfer'
                                    />
                                </RadioGroup>
                            </FormControl>
                            {paymentType === 'paypal' &&
                                <>
                                    <Typography style={{marginBottom: '1rem', marginTop: "1rem"}}>
                                        {paymentLabel}
                                    </Typography>
                                    <Grid item xs={12} md={6} style={{marginBottom: '2rem'}}>
                                        <TextField
                                            onChange={e => {
                                                    setPaymentAmount(e.target.value)
                                                }
                                            }
                                            onBlur={e => {
                                                const finalAmount = parseFloat(e.target.value).toFixed(2)
                                                setPaymentAmount(finalAmount)
                                                setFinalPaymentAmount(finalAmount)                                                
                                                if ((finalAmount < minAmount || finalAmount > maxAmount) && paymentOverride === false){
                                                    setPaymentError(`Please enter a payment amount between ${asHKCurrency(minAmount)} and ${asHKCurrency(maxAmount)}`)
                                                } else {
                                                    setPaymentError(false)
                                                }
                                            }}
                                            value={paymentAmount}
                                            label='Amount (must be in USD or HKD equivalent)'
                                            helperText={paymentHelperText}
                                            type="number"
                                            min={paymentOverride === true ? 0 : minAmount}
                                            max={maxAmount}
                                            required
                                        />
                                        {paymentError &&
                                            <Typography color="error">{paymentError}</Typography>
                                        }
                                    </Grid>

                                    <PayPalScriptProvider options={{
                                        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                        components: "buttons",
                                        currency: "HKD"
                                    }}>
                                        <PayPalButtons
                                            forceReRender={[finalPaymentAmount]}
                                            fundingSource={"paypal"}
                                            onClick={validatePaymentAmount}
                                            createOrder={(data, actions) => createOrder(data, actions)}
                                            onApprove={(data, actions) => onApprove(data, actions)}
                                        />
                                        <PayPalButtons
                                            forceReRender={[finalPaymentAmount]}
                                            fundingSource={"card"}
                                            onClick={validatePaymentAmount}
                                            createOrder={(data, actions) => createOrder(data, actions)}
                                            onApprove={(data, actions) => onApprove(data, actions)}
                                        />
                                    </PayPalScriptProvider>
                                </>
                            }
                            {paymentType === 'bankTransfer' &&
                                <>
                                    {showBankTransferRegInstructions &&
                                        <Typography style={{marginTop: '1rem'}}>
                                            Your Application for Team Registration will only be deemed complete and
                                            ready
                                            for review after valid proof of bank transfer is received by the Vis East
                                            Moot
                                            Administration.
                                        </Typography>
                                    }
                                    {!showBankTransferRegInstructions &&
                                        <Typography style={{marginTop: '1rem'}}>
                                            {paymentLabel}
                                        </Typography>
                                    }
                                    <Typography style={{marginTop: '1rem'}}>
                                            Please email your proof of bank transfer to <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a>. Please ensure that your School is included in the Reference/Message for easy traceability.
                                     </Typography>
                                    <Typography style={lineStyle}><span style={labelStyle}>Beneficiary Name:</span> Vis East Moot Foundation Ltd.</Typography>
                                    <Typography style={lineStyle}><span style={labelStyle}>Bank:</span> HSBC, 1 Queen's Road Central, Hong Kong</Typography>
                                    <Typography style={lineStyle}><span style={labelStyle}>Account Number:</span> 817 365901 001</Typography>
                                    <Typography style={lineStyle}><span style={labelStyle}>Swift Code:</span> HSBCHKHHHKH</Typography>
                                    <Typography style={lineStyle}><span style={labelStyle}>For local Hong Kong transfers, Bank Code:</span> 004</Typography>
                                    <Typography style={lineStyle}><span style={labelStyle}>Beneficiary Address:</span> Room 106, 1/Floor, Justice Place West Wing, 11 Ice House Street, Central, Hong Kong</Typography>
                                    <Typography style={lineStyle}><span
                                        style={labelStyle}>Reference:</span> For {existingTeamData.school}</Typography>
                                    <Typography style={{margin: '2.5rem 0', fontWeight: 'bold'}}>Please note that payment must be made in either Hong Kong Dollars or U.S. Dollars. Any and all bank charges must be borne by the sender.</Typography>

                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    );
}


export default connect(
    (state,ownProps ) => ({
        router: state.router,
        existingTeamData: state.registration.teamData,
        selectedTeam: state.team.selectedTeam,
        onPaymentTypeChanged: ownProps.onPaymentTypeChanged,
        onPaymentCompleted: ownProps.onPaymentCompleted,
        paymentOverride: ownProps.paymentOverride,
        showExsitingPaymentNotification: ownProps.showExsitingPaymentNotification,
        transactionType: ownProps.transactionType,
        minAmount: ownProps.minAmount,
        maxAmount: ownProps.maxAmount,
        initialAmount: ownProps.initialAmount,
        paymentHelperText: ownProps.paymentHelperText,
        paymentLabel: ownProps.paymentLabel,
        showBankTransferRegInstructions: ownProps.showBankTransferRegInstructions,
        onPaymentInProcess: ownProps.onPaymentInProcess

    }),{
        saveTeamOrder: saveTeamOrder,
        getSelectedTeam: getSelectedTeam
    }
)(PaymentProcessing)
