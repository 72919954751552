import * as actionTypes from '../actions/actionTypes/mootActionTypes'
import {combineReducers} from "redux";


const currentMoot = (state = {}, action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_CURRENT_MOOT_SUCCEEDED) {
        return payload
    } else {
        return state
    }
}

export default combineReducers({
    currentMoot
})
