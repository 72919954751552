import React, {useEffect, useState} from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty, noop, some } from 'lodash/fp'
import { isNotEmpty } from './utils/funcUtils'
import {loginUserFromSession} from "./actions/userActions";

const AuthenticatedRoute = ({
                                component: Component,
                                user,
                                loginUser = noop,
                                allowedRoles = [],
                                ...rest
                            }) => {

    const [loggingIn, setLoggingIn] = useState(true)

    useEffect(() => {
        const tryLogin = async () => {
            try {
                if (isEmpty(user)) {
                    setLoggingIn(true)
                    //refresh the user
                    await loginUser()
                    setLoggingIn(false)
                } else if (isNotEmpty(user)) {
                    setLoggingIn(false)
                }
            } catch (e) {
                setLoggingIn(false)
            }
        }
        tryLogin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isAllowedRole = user => {
        if (isEmpty(allowedRoles)) return true
        return some(role => allowedRoles.includes(role), user.roles)
    }

    return (
        <Route
            {...rest}
            render={props => {
                if (!loggingIn && isNotEmpty(user) && isAllowedRole(user)) {
                    return <Component {...props} />
                } else if (!loggingIn && (isEmpty(user) || !isAllowedRole(user))) {
                    return <Redirect to='/login'/>
                } else {
                    return <div>Logging in</div>
                }

            }}
        />
    )
}

export default connect(
    (state, ownProps) => ({
        user: state.user,
        pathname: state.router.location.pathname,
        allowedRoles: ownProps.allowedRoles
    }),
    {
        loginUser: loginUserFromSession
    }
)(AuthenticatedRoute)
