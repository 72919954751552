import * as Actions from './actionTypes/mootActionTypes'
import * as api from '../api'
import makeAction from "./actionCreator";


export const getCurrentMoot = () =>  async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_CURRENT_MOOT_REQUESTED))

    try {
        const result = await api.getCurrentMoot()
        dispatch(makeAction(Actions.GET_CURRENT_MOOT_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_CURRENT_MOOT_FAILED, {},e))
    }
}