import React from 'react'
import {DataGridPro, LicenseInfo, GridToolbarContainer } from '@mui/x-data-grid-pro';
import {join, noop} from 'lodash/fp';
import {connect} from "react-redux";
import {getAllClarifications} from "../../../reducers/adminSelectors";
import { exportAllClarifications} from '../../../actions/adminActions'
import PropTypes from "prop-types";
import {clarificationGridColumnDefinition, clientsideDownloadFile} from "../../helpers";
import {format} from "date-fns";
import {Button} from "@material-ui/core";
import {SaveAlt} from "@material-ui/icons";
LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);

const ClarificationsTable = ({ clarifications  = [], getClarifications = noop, exportAllClarifications = noop}) => {

  const [isFetching, setFetching] = React.useState(false)

  React.useEffect(() => {
    const asyncFetchData = async () => {
      try {
        setFetching(true)
        await getClarifications()
        setFetching(false)
      }catch(e){
        //TODO ?
      }
    }
    asyncFetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

    const exportClarifications = async () => {
        setFetching(true)
        const data = await exportAllClarifications()
        const blob = new Blob([join('\n',data)], {
            type: 'text/tab-separated-values',
        });

        const fullName = `Clarifications - ${format(new Date(),'yyyy-MM-dd')}.tsv`;

        clientsideDownloadFile(blob,fullName)
        setFetching(false)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Button color="primary"
                        size="small"
                        startIcon={<SaveAlt />}
                        aria-haspopup="menu"
                        onClick={exportClarifications}>Export Clarifications</Button>
            </GridToolbarContainer>
        );
    }
    return (
        <div style={{height: 700, width: '100%'}}>
          <DataGridPro
              loading={isFetching}
              rows={clarifications}
              columns={clarificationGridColumnDefinition(true)}
              disableSelectionOnClick
              components={{
                  Toolbar: CustomToolbar,
              }}
          />
        </div>
    )
  }



ClarificationsTable.propTypes = {
  clarifications: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    clarificationSought: PropTypes.string,
    rationale: PropTypes.string,
    createdAt: PropTypes.string
  })),
  getClarifications: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
      clarifications: state.admin.clarifications
    }),{
      getClarifications: getAllClarifications,
        exportAllClarifications: exportAllClarifications
    })( ClarificationsTable)