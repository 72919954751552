import * as Actions from './actionTypes/teamActionTypes'
import * as api from '../api'
import makeAction from "./actionCreator";

export const saveClarificationRequest = (clarification,overrideCode) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_TEAM_CLARIFICATION_REQUESTED, {clarification, overrideCode}))

    try {
        const result = await api.saveClarificationRequest(clarification,overrideCode)
        dispatch(makeAction(Actions.SAVE_TEAM_CLARIFICATION_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_TEAM_CLARIFICATION_FAILED, {},e))
    }
}

export const getClarificationCategories = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_TEAM_CLARIFICATION_CATEGORIES_REQUESTED))

    try {
        const result = await api.getClarificationCategories()
        dispatch(makeAction(Actions.GET_TEAM_CLARIFICATION_CATEGORIES_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_TEAM_CLARIFICATION_CATEGORIES_FAILED, {},e))
    }
}

export const getIsClarificationOpen = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GET_IS_TEAM_CLARIFICATION_OPEN_REQUESTED))

    try {
        const result = await api.getIsClarificationOpen()
        dispatch(makeAction(Actions.GET_IS_TEAM_CLARIFICATION_OPEN_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_IS_TEAM_CLARIFICATION_OPEN_FAILED, {},e))
    }
}

export const getExistingClarifications = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_TEAM_CLARIFICATIONS_REQUESTED))

    try {
        const result = await api.getExistingClarifications()
        dispatch(makeAction(Actions.GET_TEAM_CLARIFICATIONS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_TEAM_CLARIFICATIONS_FAILED, {},e))
    }
}

export const getTeam = () =>  async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_TEAM_REQUESTED))

    try {
        const result = await api.getTeam()
        dispatch(makeAction(Actions.GET_TEAM_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_TEAM_FAILED, {},e))
    }
}


export const saveTeamMember = (newTeamMember,override) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_TEAM_MEMBER_REQUESTED, {newTeamMember,override}))

    try {
        const result = await api.saveTeamMember(newTeamMember,override)
        dispatch(makeAction(Actions.SAVE_TEAM_MEMBER_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_TEAM_MEMBER_FAILED, {},e))
    }
}

export const deleteTeamMember = (id) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.DELETE_TEAM_MEMBER_REQUESTED))

    try {
        const result = await api.deleteTeamMember(id)
        dispatch(makeAction(Actions.DELETE_TEAM_MEMBER_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.DELETE_TEAM_MEMBER_FAILED, {},e))
    }
}

export const getIsTeamMemberOpen = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GET_IS_TEAM_MEMBER_OPEN_REQUESTED))

    try {
        const result = await api.getIsTeamMemberOpen()
        dispatch(makeAction(Actions.GET_IS_TEAM_MEMBER_OPEN_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_IS_TEAM_MEMBER_OPEN_FAILED, {},e))
    }
}


export const getIsTeamRegistrationOpen = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GET_IS_TEAM_REGISTRATION_OPEN_REQUESTED))

    try {
        const result = await api.getIsTeamRegistrationOpen()
        dispatch(makeAction(Actions.GET_IS_TEAM_REGISTRATION_OPEN_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_IS_TEAM_REGISTRATION_OPEN_FAILED, {},e))
    }
}

export const getIsDocumentSubmissionOpen = type => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GET_IS_TEAM_DOCUMENT_SUBMISSION_OPEN_REQUESTED))

    try {
        const result = await api.getIsTeamDocumentOpen()
        dispatch(makeAction(Actions.GET_IS_TEAM_DOCUMENT_SUBMISSION_OPEN_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_IS_TEAM_DOCUMENT_SUBMISSION_OPEN_FAILED, {},e))
    }
}


export const uploadDocument = (type, whichMoot, document, overrideCode) => async (dispatch, getState) => {
    dispatch(makeAction(Actions.UPLOAD_TEAM_DOCUMENT_REQUESTED, {type, whichMoot, document,overrideCode}))

    try {
        const result = await api.uploadDocument(type,whichMoot,document,overrideCode)
        dispatch(makeAction(Actions.UPLOAD_TEAM_DOCUMENT_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.UPLOAD_TEAM_DOCUMENT_FAILED, {},e))
        return null
    }
}



export const getSelectedTeamsPairings = () =>  async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_SELECTED_TEAMS_PAIRINGS_REQUESTED))

    try {
        const result = await api.getSelectedTeamsPairings()
        dispatch(makeAction(Actions.GET_SELECTED_TEAMS_PAIRINGS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_SELECTED_TEAMS_PAIRINGS_FAILED, {},e))
    }
}

export const saveTeamOrder = (teamId,orderJSON, description, amount,type) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_TEAM_ORDER_REQUESTED))

    try {
        const result = await api.saveTeamOrder(teamId,orderJSON, description, amount,type)
        dispatch(makeAction(Actions.SAVE_TEAM_ORDER_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_TEAM_ORDER_FAILED, {},e))
    }
}

export const getTeamPaymentHistory = (teamId) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_TEAM_PAYMENT_HISTORY_REQUESTED))

    try {
        const result = await api.getTeamPaymentHistory(teamId)
        dispatch(makeAction(Actions.GET_TEAM_PAYMENT_HISTORY_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_TEAM_PAYMENT_HISTORY_FAILED, {},e))
    }
}

export const generateInvoice = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GENERATE_TEAM_INVOICE_REQUESTED))

    try {
        const result = await api.generateTeamInvoice()
        dispatch(makeAction(Actions.GENERATE_TEAM_INVOICE_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GENERATE_TEAM_INVOICE_FAILED, {},e))
    }
}
