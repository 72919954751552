import Container from "@material-ui/core/Container";
import {CircularProgress, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {getSelectedArbitrator} from "../../../reducers/arbitratorSelectors";
import {noop} from "lodash/fp";
import {Link} from "react-router-dom";
import {getCurrentMoot} from "../../../reducers/mootSelectors";
import ordinal from "ordinal-number-suffix";



const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))
const ArbitratorDashboard = ({getSelectedArbitrator = noop, moot, arbitrator, getCurrentMoot = noop}) => {

    const [fetching,setFetching] = useState(false)
    const [error,setError] = useState()

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setFetching(true)
                await getSelectedArbitrator()
                await getCurrentMoot()
                setFetching(false)
            }catch(e){
                setFetching(false)
                setError('There was an error loading your information. Please try again later')
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const classes = useStyles()
    return (
            <Container maxWidth='lg' className={classes.root}>
                { fetching && <CircularProgress /> }
                {error && <Typography style={{ color: 'red' }}>{error}</Typography>}

                {!fetching && !error &&
                <div>

                    {arbitrator.optInShareData === null &&
                        <div>
                            <Typography variant='h5' component='h1' style={{ margin: '1rem 0' }}>Your Arbitrator Registration is incomplete.</Typography>
                            <Typography variant='body1' paragraph>Click <Link to='registration/arbitrator'>here</Link> to review your registration information</Typography>
                        </div>
                    }
                    {!arbitrator.status && arbitrator.optInShareData !== null &&
                    <div>
                        <Typography variant='h5' component='h1' style={{ margin: '1rem 0' }}>Your Arbitrator Registration for the {ordinal(moot.mootNumber)} Vis East Moot Competition has been submitted.</Typography>                       
                        <Typography variant='body1' paragraph>The Vis East Moot Administration is in the process of reviewing your Arbitrator Registration and will revert back to you shortly. If you do not hear from us within 72 hours, or if you have any questions, please contact the Vis East Moot Administration at <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a></Typography>

                    </div>
                    }
                    {arbitrator.status === 'accepted' && arbitrator.optInShareData !== null &&
                    <div>
                        <Typography variant='h5' component='h1' style={{ margin: '1rem 0' }}>Welcome {arbitrator.firstName} {arbitrator.lastName} to your Arbitrator Account!</Typography>
                        <Typography variant='body1' paragraph>Your Arbitrator Account contains the features you will need as an arbitrator for the {ordinal(moot.mootNumber)} Vis East Moot Competition (“<strong>VEM</strong>”). Please take the time to explore your Arbitrator Account (available features can be found under the MENU button on the top right of the page) and ensure that all information you have provided is accurate and always up to date.</Typography>
                        <Typography variant='body1' paragraph>We rely on the information that you provide in your Arbitrator Account for <strong>all</strong> aspects of the VEM. This includes distributing memoranda for rankings as well as scheduling oral arguments. So it is <strong>vital</strong> that you ensure that your Arbitrator Account is up to date and accurate.</Typography>
                        <Typography variant='body1' paragraph>We understand that your schedule will change over the coming months, depending on personal and professional commitments as well as potential conflicts with teams. We need to know when this happens!</Typography>
                         <ol>
                            <li><Typography variant='body1' paragraph>Please ensure that your <strong>CONFLICTS</strong> and <strong>AVAILABILITY (ORAL ARGUMENTS AND RANKING WRITTEN MEMORANDA)</strong> sections of your Arbitrator Account are <strong><u>always</u></strong> up to date.</Typography>
                                <ol type="a">
                                    <li><Typography variant='body1' paragraph><strong>CONFLICTS.</strong> If, after you have completed your Arbitrator Profile, you end up coaching or mentoring a school that is participating in the Vis East Moot and/or arbitrating a school that is participating in the Vis East Moot in Pre-Moot competitions, please ensure that you update the conflicts section of your Arbitrator Profile as soon as possible. This way we will avoid scheduling you to arbitrate that school(s) in the General Rounds of the VEM.
                                    </Typography>
                                    <Typography variant='body1' paragraph>In particular, for Pre-Moot competitions, please take great care to ensure that you do <u>not</u> arbitrator or watch a team during a Pre-Moot (or practice session) that you are already scheduled to arbitrate during the VEM General Rounds.</Typography>
                                    </li>
                                    <li><Typography variant='body1' paragraph><strong>AVAILABILITY.</strong> If your schedule of availability has changed (whether to read and rank written memoranda <u>and/or</u> for arbitrating during oral arguments), please ensure that you update the relevant availability sections of your Arbitrator Account as soon as possible.</Typography></li>
                                </ol>
                            </li>
                        </ol>
                        <Typography variant='body1' paragraph>Keeping your Arbitrator Profile continually updated will reduce emergency last-minute replacements of arbitrators for oral arguments or delayed rankings of the written memoranda and ensure that the {ordinal(moot.mootNumber)} VEM operates smoothly. The Vis East Administration and our student orators warmly appreciate your efforts to provide the students with the best experience possible.</Typography>
                        <Typography variant='body1' paragraph>For any questions or concerns about your Arbitrator Account or the Vis East Moot in general, please contact the Vis East Administration: <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a></Typography>
                    </div>
                    }
                    {arbitrator.status === 'hold' && arbitrator.optInShareData !== null &&
                    <div>
                        <Typography variant='h6' style={{ margin: '1rem 0' }}>Welcome {arbitrator.firstName} {arbitrator.lastName}!</Typography>
                        <Typography variant='body1' paragraph>Thank you for submitting your registration to arbitrate at the {ordinal(moot.mootNumber)} Annual Willem C. Vis East International Commercial Arbitration Moot (“<strong>VEM</strong>”). Your registration is currently being processed and you should hear from us within 72 hours. If you do not receive a confirmation or any problems, please contact the VEM at <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a>.</Typography>
                    </div>
                    }
                    {arbitrator.status === 'denied' && arbitrator.optInShareData !== null &&
                    <div>
                        <Typography variant='h6' style={{ margin: '1rem 0' }}>Thank you {arbitrator.firstName} {arbitrator.lastName} for submitting your interest to register as an arbitrator for the {ordinal(moot.mootNumber)} Annual Vis East Moot Competition ("Vis East Moot").</Typography>
                        <Typography variant='body1' paragraph>We regret that  we are unable to accept your registration at this year’s VEM.</Typography>
                        <Typography variant='body1' paragraph>If you have any questions, please do not hesitate to contact us at: <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a>.</Typography>
                    </div>
                    }
                </div>
                }
            </Container>
    )
}

export default connect(
    (state, ownProps) => ({
        arbitrator: state.arbitrator.selectedArbitrator,
        moot: state.moot.currentMoot
    }),{
        getSelectedArbitrator: getSelectedArbitrator,
        getCurrentMoot: getCurrentMoot
    })( ArbitratorDashboard)
