export const defaultTeam = {
    street: '',
    unit: '',
    timeZone: '',
    city:'',
    state: '',
    zip: '',
    jurisdiction: '',
    legalSystem: '',
    school: '',
    registrantFirstName:'',
    registrantLastName: '',
    registrantStreet: '',
    registrantUnit: '',
    registrantCity: '',
    registrantState: '',
    registrantJurisdiction:'',
    registrantZip: '',
    registrantInvolvement:'',
    registrantAuthorized: '',
    hasAuthorityToAddTeamMembers: false,
    acknowledgedVisEastUse: false,
    registrantDegree: '',
    registrantGender:'',
    registrantNationality:'',
    schoolInNative: '',
    registrantPhone: '',
    previousMootCount: '',
    paymentType: 'paypal'
}

export const defaultArbitrator = {
    prefix: '',
    firstName: '',
    lastName: '',
    street: '',
    unit: '',
    city: '',
    state: '',
    jurisdiction: '',
    zip: '',
    contactPhone: '',
    organization: '',
    nationality: '',
    gender:'',
    background: '',
    bio: '',
    dateQualified: null,
    optInResearch: null,
    optInShareData: null,
    optInPromote: null,
    roundsYouCanArbitrate: 4,
    availableForClaimantEvaluation: null,
    availableForRespondentEvaluation: null,
    availableForRankingClaimant: null,
    availableForRankingRespondent : null,
    arbitrationCaseCount:null,
    counselCaseCount: null,
    tribunalCaseCount: null,
    participation: null,
    occupation: null,
    yearGraduated: ''
}