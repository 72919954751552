import React from 'react';
import { Document, Page, Image, Text, Link, View, Font, StyleSheet } from '@react-pdf/renderer';
import {get, getOr, last, map} from "lodash/fp";
import {asHKCurrency, formatCurrency} from "../helpers";
import {format, utcToZonedTime} from "date-fns-tz";
import OpenSans from "../../fonts/open-sans/static/OpenSans-Regular.ttf";
import OpenSansBold from "../../fonts/open-sans/static/OpenSans-Bold.ttf";


// PRO TIP: Use the REPL tool here https://react-pdf.org/repl to visually design this document
export const Invoice = ({schoolName, schoolAddress, teamId, transactions= [],}) => {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 25,
            paddingHorizontal: 25,
            paddingBottom: 65,
            fontFamily: 'OpenSans',
        },
        bodyText:{
            fontSize: 9,
            fontFamily: 'OpenSans',
            marginBottom: 5,            
        },
        boldText:{
            fontWeight: 'bold'
        },        
        image: {
            height: 95,
            width: 95,
            alignSelf: 'center',
            marginBottom: 6
        },
        subtitle: {
            fontSize: 12,
            marginBottom: 2,
            textAlign: 'center',
        },
        subtext:{
            fontSize: 9
        },
        box:{
            border: 1,
        },
        boxHeader:{
            padding: 2,
            fontSize: 10,
            textAlign: 'center',
            backgroundColor: '#dd1f26',
        },
        whiteText: {
            color : 'white'
        },
        footerText:{
            marginTop: 10,
            marginLeft: 15,
            fontSize: 9,
        },

    });
    const totalBalance = getOr(0,'balance',last(transactions))
    const date = format(utcToZonedTime(get('createdAt',last(transactions)), 'Asia/Hong_Kong'),'dd LLL yyyy')
    const invoiceNumberDate = format(utcToZonedTime(get('createdAt',last(transactions)), 'Asia/Hong_Kong'),'yyyyMMdd')

  

    Font.register({
        family: "OpenSans",
        fontStyle: "normal",
        fontWeight: "normal",
        fonts: [
          {
            src: OpenSans,
          },
          {
            src: OpenSansBold,
            fontWeight: "bold",
          },
        ],
      });

    const formatCurrencyForInvoice = (value) => {
        const formattedValue = asHKCurrency(Math.abs(value))
        const colorValue = value < 0 ? 'red' : 'black'
        return <Text style={{color: colorValue}}>{value < 0 ? `(${formattedValue})` : `${formattedValue}`}</Text>
    }


    return (
        <Document>
            <Page style={styles.body}>
                <Image
                    style={styles.image}
                    src="/logos/East-Vis-Moot-Logo-Red.png"
                />
                <Text style={[styles.subtitle,styles.boldText]}>
                    TWENTY FIRST ANNUAL
                </Text>
                <Text style={[styles.subtitle,styles.boldText]}>
                    WILLEM C. VIS EAST INTERNATIONAL COMMERCIAL ARBITRATION MOOT
                </Text>
                <Text style={{textAlign: 'right', marginRight: 5, fontSize: 10, marginTop: 15}}>
                    {date}
                </Text>
                <Text style={[styles.boldText,{textAlign: 'center', fontSize: 10, marginTop: 6}]}>
                    INVOICE
                </Text>
                <Text style={[styles.bodyText, styles.boldText, {textAlign: 'right', marginRight: 5, fontSize: 10, marginTop: 6}]}>                    
                    Invoice #VEM-{teamId}-{invoiceNumberDate}
                </Text>
                <View style={[styles.boxHeader, { marginTop: 10, width: 250, textAlign: 'left'}]}>
                    <Text style={[styles.whiteText,styles.boldText]}>
                        Issued To:
                    </Text>
                </View>
                <View style={[styles.box,{ width: 250, padding: 4}]}>
                    <Text style={[styles.bodyText,styles.boldText]}>
                        {schoolName}
                    </Text>
                    <Text style={[styles.bodyText,styles.boldText]}>
                       {schoolAddress}
                    </Text>
                </View>

                <View style={{alignSelf: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={[styles.boxHeader, { marginTop: 20, width: 325}]}>
                            <Text style={[styles.whiteText,styles.boldText]}>
                                DESCRIPTION
                            </Text>
                        </View>
                        <View style={[styles.boxHeader, { marginTop: 20, width: 150}]}>
                            <Text style={[styles.whiteText,styles.boldText]}>
                                AMOUNT
                            </Text>
                        </View>
                    </View>
                    {(map(t => (
                        <View style={{flexDirection: 'row'}}>
                            <View style={[styles.box,{borderRight: 1,borderLeft: 1,borderTop: 0,borderBottom:1, width: 325, padding: 4}]}>
                                <Text style={[styles.bodyText,{marginBottom: 0}]}>
                                    <Text style={styles.boldText}>{format(utcToZonedTime(t.createdAt, 'Asia/Hong_Kong'),'yyyy-MM-dd')}</Text>
                                    <Text> {t.description}</Text>
                                </Text>
                            </View>
                            <View style={[styles.box,{borderRight: 1,borderLeft: 1,borderTop: 0,borderBottom:1, width: 150, padding: 4, textAlign: 'right'}]}>
                                <Text style={[styles.bodyText,{marginBottom: 0}]}>{formatCurrencyForInvoice(t.amount)}</Text>
                            </View>
                        </View>
                    ),transactions)
                    )}
                    <View style={{flexDirection: 'row',textAlign: 'right'}}>
                        <View style={[styles.box,{borderRight: 1,borderLeft: 1,borderTop: 0,borderBottom:1, width: 325, padding: 4}]}>
                            <Text style={[styles.bodyText, styles.boldText,{marginBottom: 0}]}>TOTAL DUE:</Text>
                        </View>
                        <View style={[styles.box, {borderRight: 1,borderLeft: 1,borderTop: 0,borderBottom:1, width: 150, padding: 4}]}>
                            <Text style={[styles.bodyText, styles.boldText, {marginBottom: 0}]}>{formatCurrencyForInvoice(totalBalance)}</Text>
                        </View>
                    </View>
                </View>
                <View style={{marginTop: 10}}>
                    <Text style={styles.subtext}>Please remit payment via PayPal (directly through your Team Account) or by Bank Transfer using the following instructions.</Text>
                    <Text style={styles.subtext}><Text style={styles.boldText}>Bank Transfer: </Text>For costs associated with your own bank, teams are responsible for contacting their bank directly</Text>
                </View>
                <View style={{alignSelf: 'center', marginTop: 5}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={[styles.boxHeader, { width: '50%'}]}>
                            <Text style={[styles.whiteText,styles.boldText]}>
                                PAYPAL
                            </Text>
                        </View>
                        <View style={[styles.boxHeader, { width: '50%'}]}>
                            <Text style={[styles.whiteText,styles.boldText]}>
                                BANK TRANSFER
                            </Text>
                        </View>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <View style={[styles.box,{width: '50%', padding: 5}]}>
                            <Text style={styles.bodyText}>
                                1. Choose the "Payment & Finances" option on your Team Account and follow the instructions.
                            </Text>
                            <Text style={styles.bodyText}>
                                2. If you are experiencing problems with your Team Account, you can alternatively click on <Link href="https://www.paypal.com/myaccount/transfer/">Send & Request</Link>.
                            </Text>
                            <Text style={styles.bodyText}>
                                3. Enter info@cisgmoot.org and click 
                                <Text style={styles.boldText}> Next.</Text>
                            </Text>
                            <Text style={styles.bodyText}>
                               4. Type the amount under "Recipient gets" Add a note <Text style={styles.boldText}>"{schoolName}"</Text> and click <Text style={styles.boldText}>Continue.</Text>
                            </Text>
                            <Text style={styles.bodyText}>
                                5. Choose your preferred payment method and tap <Text style={styles.boldText}>Next.</Text>
                            </Text>
                            <Text style={[styles.bodyText,{marginBottom: 0}]}>
                                6. Review the information and tap 
                                <Text style={styles.boldText}> Send now</Text>.
                            </Text>
                        </View>
                        <View style={[styles.box,{width: '50%', padding: 5}]}>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Beneficiary Name: </Text>
                                    Vis East Moot Foundation Ltd.                               
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Bank: </Text>
                                HSBC, 1 Queen’s Road Central, Hong Kong
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Account Number: </Text>
                                817 365901 001
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Swift Code: </Text>
                                HSBCHKHHHKH
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>For local Hong Kong transfers, Bank Code: </Text>
                                004
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Beneficiary Address: </Text>
                                Room 106, 1/Floor, Justice Place West Wing, 11 Ice House Street, Central, Hong Kong
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Reference: </Text>
                                For {schoolName}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.footerText}>
                    <Text style={{marginBottom: 3}}>
                        1. As set out in 21st Vis East Rules, the registration fee for the <Text style={styles.boldText}>21st Vis East Moot of HK$10,000.00</Text> applies to teams comprised of 4 – 8 team members. Teams with fewer than 4 members may pay a reduced registration fee of <Text style={styles.boldText}>HK$9,500.00</Text>. Teams with more than 8 members pay the registration fee of <Text style={styles.boldText}>HK$10,000.00 <Text style={{textDecoration: 'underline'}}>plus</Text> HK$1,500.00</Text> for each additional member over 8 who is coming to Hong Kong.
                    </Text>
                    <Text style={{marginBottom: 3}}>
                        2. Please ensure that you reference your school’s name when you remit payment.
                    </Text>
                    <Text style={{marginBottom: 3}}>
                        3. For good order, and to ensure we are able to credit your team's account, please also send proof of payment to the Vis East Administration via email: <Link href="mailto:info@cisgmoot.org">info@cisgmoot.org</Link>.
                    </Text>
                    <Text style={{marginBottom: 3}}>
                        4. Payment of the outstanding balance of the registration fee including any associated handling fees is due by <Text style={[styles.boldText,{textDecoration: 'underline'}]}>7 December 2023</Text>, unless prior arrangements have been approved in writing by the Vis East administration. Otherwise, a team which has not paid by the deadline will be deemed to have withdrawn from the competition. Any team that has withdrawn following acceptance of its application will lose its non-refundable deposit.
                    </Text>
                    <Text>
                        5. The Rules of the 21st Vis East Moot are available on the website: www.cisgmoot.org.
                    </Text>
                </View>
            </Page>
        </Document>
    )
}