import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import React, {useEffect, useState} from 'react'
import Select from "../common/Select";
import {noop, get} from "lodash/fp";
import TextInput from "../common/TextInput";
import { connect } from 'react-redux'
import * as actions from '../../actions/teamActions'
import PropTypes from "prop-types";
import {Button, CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "../common/TextInput";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {isNotEmpty} from "../../utils/funcUtils";
import { ACCEPTED } from '../../consts/status.js'
import Unauthorized from "../Unauthorized";
import {getSelectedTeam, getExistingClarifications, getClarificationCategories} from '../../reducers/teamSelectors'
import {clarificationGridColumnDefinition} from "../helpers";

const ClarificationRequest = ({
                                  router,
                                  clarificationCategories=[],
                                  isClarificationOpen = {},
                                  clarificationSaveError = '',
                                  existingClarifications = [],
                                  team = {},
                                  saveClarificationRequest = noop,
                                  getIsClarificationOpen = noop,
                                  getClarificationCategories = noop,
                                  getExistingClarifications = noop,
                                  getSelectedTeam = noop}) => {


    const defaultClarification = { category: '', rationale: '', clarificationSought: ''}
    const [clarification,setClarification] = useState(defaultClarification )
    const [isSaving,setIsSaving] = useState(false);
    const [isLoading,setIsLoading] = useState(true);

    const override = router.location.query.override

    const onSave = async (e) => {

        try {
            e.preventDefault()
            setIsSaving(true)
            const newRequest = await saveClarificationRequest(clarification,override)
            if (newRequest) {
                setClarification(defaultClarification)
            }
        }
        finally {
            setIsSaving(false)
        }
    }

    useEffect(() => {
        async function getData() {
            await getIsClarificationOpen()

            await getClarificationCategories()
            await getExistingClarifications()
            await getSelectedTeam()
            setIsLoading(false)
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const isAllowed = get('status',team) === ACCEPTED


  return (
    <Container style={{paddingBottom: '10rem', paddingTop: '4rem'}}>
        { isLoading &&
            <CircularProgress />
        }
        {!isLoading && !isAllowed &&
            <Unauthorized />
        }
        {!isLoading && isAllowed && !isClarificationOpen.notOpenYet && isClarificationOpen.closed && override !== isClarificationOpen.moot?.openOverrideCode &&
            <div>
                <Typography variant='h4' component='h1'>Clarification Requests are closed</Typography>
            </div>
        }
        {!isLoading && isAllowed && isClarificationOpen.notOpenYet && !isClarificationOpen.closed && override !== isClarificationOpen.moot?.openOverrideCode &&
            <div>
                <Typography variant='h4' component='h1'>Clarification Requests additions will open soon</Typography>
            </div>
        }

        {!isLoading && isAllowed && ((!isClarificationOpen.notOpenYet && !isClarificationOpen.closed) || override === isClarificationOpen.moot?.openOverrideCode) &&
        <form onSubmit={onSave}>
            <Typography variant='h4' component='h1'>Clarification Request </Typography>
            <Select name='category' required
                    onChange={e => setClarification({...clarification, category: e.target.value})}
                    value={clarification.category} label='Category' options={clarificationCategories}/>
            {clarification.category === 'Other questions' && (
                <>
                    <Grid item xs={12} md={6}>
                        <TextField
                            onChange={e => setClarification({...clarification, otherCategory: e.target.value})}
                            value={clarification.otherCategory}
                            name='otherCategory'
                            label='Enter Category'
                        />
                    </Grid>
                </>
            )}
            <TextInput required minRows={4} multiline label='Clarification sought'
                       value={clarification.clarificationSought}
                       onChange={e => setClarification({...clarification, clarificationSought: e.target.value})}/>
            <TextInput required minRows={4} multiline label='Rationale' value={clarification.rationale}
                       onChange={e => setClarification({...clarification, rationale: e.target.value})}/>
            <Button variant='contained'
                    type='submit'
                    disabled={isSaving}>Submit</Button>
            {clarificationSaveError &&
            <Typography color='error'>{clarificationSaveError}</Typography>
            }
        </form>
        }
        {isNotEmpty(existingClarifications) && isAllowed &&  ((!isClarificationOpen.notOpenYet && !isClarificationOpen.closed) || (override === isClarificationOpen.moot?.openOverrideCode)) &&
        <div style={{height: 700, marginTop: '2rem', width: '100%'}}>
            <h3>Existing clarifications</h3>
            <DataGridPro
                rows={existingClarifications}
                columns={clarificationGridColumnDefinition()}
                disableSelectionOnClick
            />
        </div>
        }
    </Container>
  )
}


ClarificationRequest.propTypes = {
    clarificationCategories: PropTypes.arrayOf(PropTypes.string),
    isClarificationOpen : PropTypes.object,
    clarificationSaveError : PropTypes.string,
    existingClarifications: PropTypes.arrayOf(PropTypes.shape({
            category: PropTypes.string,
            clarificationSought: PropTypes.string,
            rationale: PropTypes.string,
            createdAt: PropTypes.string
        })),
    getIsClarificationOpen: PropTypes.func,
    getClarificationCategories: PropTypes.func,
}

export default connect(
    (state, ownProps) => ({
        router: state.router,
        clarificationCategories : state.team.clarificationCategories,
        isClarificationOpen: state.team.isClarificationOpen,
        clarificationSaveError: state.team.clarificationSaveError,
        existingClarifications: state.team.existingClarifications,
        team: state.team.selectedTeam
    }),
    {
        saveClarificationRequest: actions.saveClarificationRequest,
        getIsClarificationOpen: actions.getIsClarificationOpen,
        getClarificationCategories: getClarificationCategories,
        getExistingClarifications: getExistingClarifications,
        getSelectedTeam: getSelectedTeam
    }
)(ClarificationRequest)