import * as Actions from './actionTypes/arbitratorActionTypes'
import * as api from '../api'
import makeAction from "./actionCreator";
import {TEAM_ROLE_TYPE} from "../utils/constants";

export const addAvailability = date => (dispatch) => {
    dispatch(makeAction(Actions.ADD_ARBITRATOR_AVAILABILITY,date))
}

export const removeAvailability = date => (dispatch) => {
    dispatch(makeAction(Actions.REMOVE_ARBITRATOR_AVAILABILITY, date))
}

export const saveAvailability = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_ARBITRATOR_AVAILABILITY_REQUESTED))

    try {
        const result = await api.saveArbitratorAvailability(getState().arbitrator.selectedArbitratorSchedule)
        dispatch(makeAction(Actions.SAVE_ARBITRATOR_AVAILABILITY_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_ARBITRATOR_AVAILABILITY_FAILED, {},e))
        return null
    }
}

export const getArbitrationDateTimes = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GET_ARBITRATION_DATE_TIMES_REQUESTED))

    try {
        const result = await api.getArbitrationDateTimes()
        dispatch(makeAction(Actions.GET_ARBITRATION_DATE_TIMES_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ARBITRATION_DATE_TIMES_FAILED, {},e))
    }
}

export const getArbitrator = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GET_ARBITRATOR_REQUESTED))

    try {
        const result = await api.getArbitrator()
        dispatch(makeAction(Actions.GET_ARBITRATOR_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ARBITRATOR_FAILED, {},e))
    }
}

export const getArbitratorClaimantPairings = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GET_ARBITRATIOR_CLAIMANT_PAIRINGS_REQUESTED))

    try {
        const result = await api.getArbitratorClaimantPairings()
        dispatch(makeAction(Actions.GET_ARBITRATIOR_CLAIMANT_PAIRINGS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ARBITRATIOR_CLAIMANT_PAIRINGS_FAILED, {},e))
    }
}

export const getArbitratorRespondentPairings = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GET_ARBITRATIOR_RESPONDENT_PAIRINGS_REQUESTED))

    try {
        const result = await api.getArbitratorRespondentPairings()
        dispatch(makeAction(Actions.GET_ARBITRATIOR_RESPONDENT_PAIRINGS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ARBITRATIOR_RESPONDENT_PAIRINGS_FAILED, {},e))
    }
}

export const getJuryTeams = (role) => async (dispatch, getState) => {
    if (role === TEAM_ROLE_TYPE.CLAIMANT)
        await getClaimantJuryTeams(role)(dispatch,getState)
    else if (role === TEAM_ROLE_TYPE.RESPONDENT)
        await getRespondentJuryTeams(role)(dispatch,getState)
}

const getClaimantJuryTeams = role => async (dispatch,getState)=> {
    dispatch(makeAction(Actions.GET_CLAIMANT_JURY_TEAMS_REQUESTED))

    try {
        const result = await api.getJuryTeams(role)
        dispatch(makeAction(Actions.GET_CLAIMANT_JURY_TEAMS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_CLAIMANT_JURY_TEAMS_FAILED, {},e))
    }
}

const getRespondentJuryTeams = role => async (dispatch,getState)=> {
    dispatch(makeAction(Actions.GET_RESPONDENT_JURY_TEAMS_REQUESTED))

    try {
        const result = await api.getJuryTeams(role)
        dispatch(makeAction(Actions.GET_RESPONDENT_JURY_TEAMS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_RESPONDENT_JURY_TEAMS_FAILED, {},e))
    }
}

export const saveRankings = (rankings,roleType) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_ARBITRATOR_RANKINGS_REQUESTED))

    try {
        const result = await api.saveArbitratorRankings(rankings,roleType)
        dispatch(makeAction(Actions.SAVE_ARBITRATOR_RANKINGS_SUCCEEDED, result))
        await getArbitrator()(dispatch,getState)

        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_ARBITRATOR_RANKINGS_FAILED, {},e))
        return null
    }
}


export const saveJuryRankings = (rankings,roleType) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_JURY_RANKINGS_REQUESTED))

    try {
        const result = await api.saveJuryRankings(rankings,roleType)
        dispatch(makeAction(Actions.SAVE_JURY_RANKINGS_SUCCEEDED, result))
        await getArbitrator()(dispatch,getState)

        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_JURY_RANKINGS_FAILED, {},e))
        return null
    }
}

export const uploadCriticalCommentsDocument = (roleType,teamId, document) => async (dispatch, getState) => {
    dispatch(makeAction(Actions.UPLOAD_CRITICAL_COMMENTS_DOCUMENT_REQUESTED, {roleType, teamId, document}))

    try {
        const result = await api.uploadCriticalCommentsDocument(roleType,teamId,document)
        dispatch(makeAction(Actions.UPLOAD_CRITICAL_COMMENTS_DOCUMENT_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.UPLOAD_CRITICAL_COMMENTS_DOCUMENT_FAILED, {},e))
        return null
    }
}

export const saveArbitratorProfile = (id, profile) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_ARBITRATOR_PROFILE_REQUESTED))

    try {
        const result = await api.saveArbitratorProfile(id, profile)
        dispatch(makeAction(Actions.SAVE_ARBITRATOR_PROFILE_SUCCEEDED, result))
        await getArbitrator()(dispatch,getState)

        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_ARBITRATOR_PROFILE_FAILED, {id, profile},e))
        return null
    }
}

export const setArbitratorSchoolsToWatch = schools => (dispatch,getState) => {
    dispatch(makeAction(Actions.SET_ARBITRATOR_SCHOOLS_TO_WATCH,schools))
}
export const setArbitratorAffiliatedSchools = schools => (dispatch,getState) => {
    dispatch(makeAction(Actions.SET_ARBITRATOR_AFFILIATED_SCHOOLS,schools))
}

export const setArbitratorRoundsYouCanArbitrate = roundCount => (dispatch,getState) => {
    dispatch(makeAction(Actions.SET_ARBITRATOR_ROUNDS_YOU_CAN_ARBITRATE,roundCount))
}

export const getArbitratorOralArgumentSchedule = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ARBITRATOR_ORAL_ARGUMENT_SCHEDULE_REQUESTED))
    try {
        const result = await api.getArbitratorOralArgumentSchedule()
        dispatch(makeAction(Actions.GET_ARBITRATOR_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ARBITRATOR_ORAL_ARGUMENT_SCHEDULE_FAILED, {},e))
    }
}

export const getIsArbitratorRegistrationOpen = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_IS_ARBITRATOR_REGISTRATION_OPEN_REQUESTED))

    try {
        const result = await api.getIsArbitratorRegistrationOpen()
        dispatch(makeAction(Actions.GET_IS_ARBITRATOR_REGISTRATION_OPEN_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_IS_ARBITRATOR_REGISTRATION_OPEN_FAILED, {},e))
    }

}